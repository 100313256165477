import React from "react";
import "./welcome.scss";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="home__w-section">
      <div className="home__w-section--container">
        <div className="heading">
          <h2>welcome to aspect aluminium and glass</h2>
          <div className="underline"></div>
        </div>
        <div className="row">
          <div className="tag">What we do</div>
          <div className="row1">
            <p>
              Aspect Aluminium and glass is a innovative manufacturing company
              based in Dubai, United Arab Emirates providing creative products &
              solutions. We are specialized in design, manufacture &
              installation of Aluminum, glass, upvc, Glazing work in Dubai and
              all over UAE. We offer a wide range of transparent services with
              aesthetic design and focused on delivering the best quality of
              products. If you're interested in enriching your home or business
              with a robust yet elegant look, you're in the right place!
            </p>

            <div className="boxes">
              <div className="box">
                <img src="/img/icons/box1.svg" alt="installation" />
                <div className="right">
                  <h3>installation</h3>
                  <p> professional, fast and clean</p>
                </div>
              </div>
              <div className="box">
                <img src="/img/icons/box2.svg" alt="dimensions and drawings" />
                <div className="right">
                  <h3>dimensions and drawings</h3>
                  <p> all products are tailored to existing spaces</p>
                </div>
              </div>
              <div className="box">
                <img src="/img/icons/box3.svg" alt="durable" />
                <div className="right">
                  <h3>durable</h3>
                  <p> high grade durable materials</p>
                </div>
              </div>
              <div className="box">
                <img src="/img/icons/box4.svg" alt="cost effective" />
                <div className="right">
                  <h3>cost effective</h3>
                  <p> affordable range of products and services</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="tag">Explore our Extensive range of products in:</div>
          <div className="row1">
            <div className="imgBox">
              <Link className="img" to={"/products/aluminium"}>
                <img src="/img/aluminium-entry-door.jpg" alt="aluminum" />
              </Link>

              <Link to={"/products/aluminium"}>
                <h3> Aluminum</h3>
              </Link>
            </div>

            <div className="imgBox">
              <Link className="img" to={"/products/glass"}>
                <img src="/img/curtain-wall-dubai.jpg" alt="glass" />
              </Link>
              <Link to={"/products/glass"}>
                <h3>Glass</h3>
              </Link>
            </div>
            <div className="imgBox">
              <Link className="img" to={"/products/upvc"}>
                <img
                  src="/img/projects/upvc/upvc-tilt-and-turn.jpg"
                  alt="upvc"
                />
              </Link>
              <Link to={"/products/upvc"}>
                <h3>UPVC</h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
