import "./gardenRooms.scss";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import { Helmet } from "react-helmet";

const GardenRooms = () => {
  return (
    <div className="gardenRooms">
      <SimpleHeader title={"garden rooms"} />
      <Helmet>
        <title>garden rooms | Aspect Aluminium</title>
        <meta
          name="description"
          content="Create a versatile and tranquil outdoor retreat with our stunning garden rooms, tailored to your UAE property for relaxation, work, or entertainment. Aspect Aluminium and Glass Industry combines the latest products and technology with our traditional craftsmanship and experience, to bring you the best contemporary garden rooms possible."
        />
        <meta
          name="keywords"
          content="garden rooms, best garden rooms, outdoor garden rooms, luxury garden office, garden office studio, backyard room, outdoor living spaces, garden cabins, garden studios, garden retreats, winter garden, backyard rooms, garden extension, garden sanctuaries"
        />
      </Helmet>

      <div className="gardenRooms--container">
        <div className="row">
          <div className="content">
            <h2>Contemporary Garden Rooms</h2>
            <p>
              Here at Aspect Aluminium and Glass Industry, we strive to combine
              the latest products and technology with our traditional
              craftsmanship and experience, to bring you the best contemporary
              garden rooms possible. Using a mix of composite materials, we can
              now offer buildings with no external timber and are virtually
              maintenance free.
            </p>
            <p>
              Our Contemporary Garden Room Collection offers clean lines and
              large full drop glass in the windows and doors. Generally in a
              pent roof to keep under the planning rule, our garden rooms are
              also available in an apex roof.
            </p>
          </div>
          <div className="img">
            <img
              src="/img/projects/glass-garden-rooms/garden-rooms1.jpg"
              alt="Glass Rooms"
            />
          </div>
        </div>
        <div className="row">
          <div className="content">
            <h2>Garden Rooms with Storage/Side Shed</h2>
            <p>
              If you are looking to build a garden summer room or a hideaway,
              but you still need storage for your garden tools, we can build a
              garden room with storage. General garden rooms can be used in
              various ways, for instance, it can be a multi-use garden room with
              a concealed side shed. Your garden room could even have different
              access points, with a secret shed concealed at the rear. Speak to
              one of our consultants about the ideal space for you and your
              family. Whether it's a garden office, a playroom or a workshop, we
              can suggest a garden room to meet your needs.
            </p>
          </div>

          <div className="img">
            <img
              src="/img/projects/glass-garden-rooms/garden-rooms3.jpg"
              alt="Glass Rooms"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GardenRooms;
