import "./navbar.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose, MdMenu, MdOutlineKeyboardArrowDown } from "react-icons/md";

import { useState } from "react";
import Search from "../search/Search";
import { useEffect } from "react";
import { useStickyNav } from "../../hooks/useStickyNav";

const Navbar = () => {
  const location = useLocation();
  const [windowsLink, setWindowsLink] = useState(false);
  const [doorsLink, setDoorsLink] = useState(false);
  const [contactLink, setContactLink] = useState(false);
  const [menu, setMenu] = useState(false);

  const { sentinel, isSticky } = useStickyNav();
  useEffect(() => {
    setMenu(false);
  }, [location.pathname]);
  return (
    <>
      {sentinel}
      <div className={isSticky ? "navbar sticky" : "navbar"}>
        <nav>
          <div className="nav--left">
            <Link to="/" className="logo">
              <img src="/img/logo.png" alt="aspect-global-logo" />
            </Link>
            <Search />
            {menu ? (
              <MdClose className="menu" onClick={() => setMenu(false)} />
            ) : (
              <MdMenu className="menu" onClick={() => setMenu(true)} />
            )}
          </div>

          <ul className={menu ? "navlinks open" : "navlinks"}>
            <li className="navlinks--link">
              <NavLink
                to={"/"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                Home
              </NavLink>
            </li>
            <li className="navlinks--link">
              <NavLink
                to={"/glass-rooms"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                glass rooms
              </NavLink>
            </li>
            <li className="navlinks--link">
              <NavLink
                to={"/garden-rooms"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                garden rooms
              </NavLink>
            </li>
            <li
              className="navlinks--link"
              onMouseLeave={() => setWindowsLink(false)}
            >
              <span
                onMouseEnter={() => setWindowsLink(true)}
                onClick={() => setWindowsLink(!windowsLink)}
              >
                windows
                <MdOutlineKeyboardArrowDown />
              </span>
              <ul
                className={
                  windowsLink
                    ? "navlinks--link__sub active"
                    : "navlinks--link__sub"
                }
              >
                <li>
                  <NavLink
                    to="/products/view-all/windows"
                    className={({ isActive }) =>
                      isActive ? "subNavActive" : ""
                    }
                  >
                    All Windows
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/upvc/windows"
                    className={({ isActive }) =>
                      isActive ? "subNavActive" : ""
                    }
                  >
                    UPVC
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/aluminium/windows"
                    className={({ isActive }) =>
                      isActive ? "subNavActive" : ""
                    }
                  >
                    aluminium
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/products/view-all/windows"
                    className={({ isActive }) =>
                      isActive ? "subNavActive" : ""
                    }
                  >
                    glass
                  </NavLink>
                </li>
              </ul>
            </li>

            <li
              className="navlinks--link"
              onMouseLeave={() => setDoorsLink(false)}
            >
              <span
                onMouseEnter={() => setDoorsLink(true)}
                onClick={() => setDoorsLink(!doorsLink)}
              >
                doors
                <MdOutlineKeyboardArrowDown />
              </span>

              <ul
                className={
                  doorsLink
                    ? "navlinks--link__sub active"
                    : "navlinks--link__sub"
                }
              >
                <li>
                  <NavLink
                    to="/products/view-all/doors"
                    className={({ isActive }) =>
                      isActive ? "subNavActive" : ""
                    }
                  >
                    All Doors
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/upvc/doors"
                    className={({ isActive }) =>
                      isActive ? "subNavActive" : ""
                    }
                  >
                    UPVC
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/aluminium/doors"
                    className={({ isActive }) =>
                      isActive ? "subNavActive" : ""
                    }
                  >
                    aluminium
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/glass/doors"
                    className={({ isActive }) =>
                      isActive ? "subNavActive" : ""
                    }
                  >
                    glass
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="navlinks--link">
              <NavLink
                to={"/projects"}
                className={({ isActive }) => (isActive ? "navActive" : "")}
                end
              >
                projects
              </NavLink>
            </li>

            <li
              className="navlinks--link"
              onMouseLeave={() => setContactLink(false)}
            >
              <span
                onMouseEnter={() => setContactLink(true)}
                onClick={() => setContactLink(!contactLink)}
              >
                contact
                <MdOutlineKeyboardArrowDown />
              </span>
              <ul
                className={
                  contactLink
                    ? "navlinks--link__sub active"
                    : "navlinks--link__sub"
                }
              >
                <li>
                  <Link to="/quotation">Get a quote</Link>
                </li>
                <li>
                  <Link to="/contact">contact us</Link>
                </li>
                <li>
                  <Link to="/about">about</Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
