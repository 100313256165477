import React, { useContext } from "react";
import FormContext from "../../context/MultiStepFormContext";
import { Button } from "antd";
import emailjs from "@emailjs/browser";
import { toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Review = () => {
  const { formDetails, next, prev } = useContext(FormContext);
  const navigate = useNavigate();
  const toastOptions = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    bodyClassName: "toast-font-size",
  };
  const onSubmit = async () => {
    try {
      await emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID_FOR_QUOTATION,
          formDetails,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (result) => {
            next();
            navigate("/");
            toast(
              "Thank you for contacting us. We will get back to you shortly.",
              toastOptions
            );
          },
          (error) => {
            // console.log(error);
            toast("Couldnt send your email. Try again Later", toastOptions);
          }
        );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="field details--wrapper">
      <div className="details--box">
        <h3>Details</h3>

        <p>
          Product: <span>{formDetails.type}</span>
        </p>
        {formDetails.gridH && (
          <p>
            Horizontal Grids:
            <span> {formDetails.gridH}</span>
          </p>
        )}
        {formDetails.gridV && (
          <p>
            Vertiacal Grids:<span> {formDetails.gridV}</span>
          </p>
        )}

        {formDetails.width && (
          <p>
            width: <span>{formDetails.width}mm</span>
          </p>
        )}
        {formDetails.height && (
          <p>
            Height: <span>{formDetails.height}mm</span>
          </p>
        )}

        <p>
          Description: <span>{formDetails.description}</span>
        </p>
        <h3>Contact Details:</h3>
        <p>
          Name: <span>{formDetails.name}</span>
        </p>
        <p>
          Email: <span>{formDetails.email}</span>
        </p>
        <p>
          Phone: <span>{formDetails.phone}</span>
        </p>
      </div>

      <div className="buttons">
        <Button type={"default"} onClick={prev}>
          Back
        </Button>
        <Button type={"primary"} onClick={onSubmit}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default Review;
