import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./headerSwiper.scss";

import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";

const HeaderSwiper = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class=${className} ></span>`;
    },
  };
  return (
    <Swiper
      direction={"vertical"}
      pagination={pagination}
      loop={true}
      modules={[Autoplay, Pagination]}
      className="headerSwiper"
      speed={3000}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
    >
      <SwiperSlide>
        <div className="slide slide1">
          <div className="content">
            <h1>sliding and folding doors</h1>
            <p>
              Find the highest quality UPVC sliding and folding doors in UAE
            </p>
            <Link to={"/quotation"}>
              <button className="btn btn--main">get a quote</button>
            </Link>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slide slide2">
          <div className="content">
            <h1>aluminium and glass windows</h1>
            <p>
              explore wide range of easy to use, long lasting aluminium and
              glass windows
            </p>
            <Link to={"/quotation"}>
              <button className="btn btn--main">get a quote</button>
            </Link>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slide slide3">
          <div className="content">
            <h1>garden rooms</h1>
            <p>
              choose to use your own slice of outdoor living with perfect garden
              room
            </p>
            <Link to={"/quotation"}>
              <button className="btn btn--main">get a quote</button>
            </Link>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slide slide4">
          <div className="content">
            <h1>glass rooms</h1>
            <p>
              discover perfect way to enjoy views of your surroundings with
              elegant glassrooms
            </p>
            <Link to={"/quotation"}>
              <button className="btn btn--main">get a quote</button>
            </Link>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default HeaderSwiper;
