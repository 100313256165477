import { Link } from "react-router-dom";
import "./cards.scss";

const Cards = (card) => {
  return (
    <div
      className="card"
      data-aos="flip-left"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
    >
      <img src={card.img} alt={card.heading} />
      <div className="content">
        <h3 className="sub-heading">{card.heading}</h3>
        <p>{card.paragraph}</p>
        <Link to={card.link}>
          <button className="btn"> view more</button>
        </Link>
      </div>
    </div>
  );
};

export default Cards;
