import "./searchResults.scss";
import ProductsHandler from "../../components/productsHandler/ProductsHandler";
import { useContext } from "react";
import { SearchContext } from "../../context/SearchContext";
import { products } from "../../data/data";

const SearchResults = () => {
  const { searchKey, searchResults } = useContext(SearchContext);
  const lists = products.filter((item) =>
    searchResults.find((search) => search === item.heading.toLowerCase())
  );
  // console.log(lists);
  return (
    <div className="searchPage">
      <div className="searchHeader">
        <div className="heading">
          <h2>
            Search Results for: <span>'{searchKey}'</span>
          </h2>
          <div className="underline"></div>
        </div>
      </div>
      {lists.length === 0 ? (
        <div className="search--fail">
          sorry, Couldn't find what you are looking for &#128543;.
        </div>
      ) : (
        <ProductsHandler lists={lists} />
      )}
    </div>
  );
};

export default SearchResults;
