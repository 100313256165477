import React from "react";
import Header from "../../components/header/Header";
import Welcome from "../../components/welcome/Welcome";
import Service from "../../components/services/Service";
import MainCast from "../../components/main-cast/MainCast";
import StairsAndGlass from "../../components/stairsAndGlass/StairsAndGlass";
import BgSection from "../../components/bgSection/BgSection";
import Contact from "../../components/contact/Contact";
import Suppliers from "../../components/suppliers/Suppliers";

const Home = () => {
  return (
    <>
      <Header />
      <Welcome />
      <Service />
      <MainCast />
      <StairsAndGlass />
      <BgSection />
      <Suppliers />
      <Contact />
    </>
  );
};

export default Home;
