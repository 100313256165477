import React from "react";
import {
  FaEnvelope,
  FaFilePdf,
  FaMoon,
  FaPhoneAlt,
  FaSun,
} from "react-icons/fa";
import "./topbar.scss";
import Pdf from "../../Aspect-Aluminium-Industry-profile.pdf";
import Switch from "react-switch";
import { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext";

const Topbar = () => {
  const { darkMode, setDarkMode } = useContext(DarkModeContext);
  return (
    <div className="topbar">
      <div className="topbar__left">
        <div className="topbar__left--element">
          <FaPhoneAlt className="icon" />
          <a href="tel: +97145586191"> +971 4 558 6191</a>
        </div>
        <div className="topbar__left--element">
          <FaEnvelope className="icon" />
          <a href="mailto:sales@aspectuae.com">sales@aspectuae.com</a>
        </div>
        <div className="topbar__left--element">
          <FaFilePdf className="icon" />
          <a
            className="brochure"
            href={Pdf}
            // download={"Aspect Aluminium & Glass"}
            target="_blank"
            rel="noreferrer"
          >
            Download Company Profile
          </a>
        </div>
      </div>
      <div className="topbar__right">
        <div className="switch">
          <FaSun className={!darkMode ? "icon active" : "icon"} />
          <Switch
            onChange={() => setDarkMode(!darkMode)}
            checked={darkMode}
            onColor="#048bf3"
            onHandleColor="#fff"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={10}
            width={30}
            className="react-switch"
            id="material-switch"
          />
          <FaMoon className={darkMode ? "icon active" : "icon"} />
        </div>{" "}
      </div>
    </div>
  );
};

export default Topbar;
