import "./productHeader.scss";
import { products } from "../../data/data";
import { Link } from "react-router-dom";

const ProductHeader = ({ title }) => {
  const list = products.find((el) => {
    const l = el.link.split("/").at(-1);
    return l === title;
  });

  return (
    <div className="productHeader">
      <h1>{list.heading}</h1>
      <div className="breadCrumb">
        <Link to={`/products/${list.material}`}>{list.material}</Link>
        <span>{list.heading}</span>
      </div>
    </div>
  );
};

export default ProductHeader;
