import React, { useContext, useState } from "react";

import { Button, Radio, Select, Form } from "antd";
import { products } from "../../data/data";
import FormContext from "../../context/MultiStepFormContext";
import { DarkModeContext } from "../../context/darkModeContext";

const Type = () => {
  const [checkedValue, setCheckedValue] = useState("");
  const [variant, setVariant] = useState("");
  const { formDetails, setFormDetails, next } = useContext(FormContext);
  const { darkMode } = useContext(DarkModeContext);

  const options = products
    .filter((el) => el.type === checkedValue)
    .map((item) => ({
      value: item.heading,
      label: item.heading.replace("UPVC", ""),
    }));

  const handleNextClick = () => {
    setFormDetails({
      ...formDetails,
      type: variant,
    });
    next();
  };

  return (
    <Form className="field type__wrapper" onFinish={handleNextClick}>
      <Form.Item
        name="Product"
        rules={[{ required: true, message: "Please Choose The Product!" }]}
      >
        <Radio.Group
          onChange={(e) => {
            setCheckedValue(e.target.value);
            setVariant(undefined);
          }}
          value={checkedValue}
          name="radio"
        >
          <Radio value={"windows"}>Windows</Radio>
          <Radio value={"doors"}>Doors</Radio>
        </Radio.Group>
      </Form.Item>

      {checkedValue !== "" && (
        <Form.Item name="Type" label="Type" rules={[{ required: true }]}>
          <Select
            style={{ width: 280 }}
            placeholder={`Select ${checkedValue} type`}
            options={options}
            onChange={(value) => setVariant(value)}
            value={variant}
            dropdownStyle={{
              color: "",
              backgroundColor: darkMode ? "#09111a" : "#ffffff",
            }}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Button type={"primary"} htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Type;
