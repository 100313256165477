import React from "react";
import "./header.scss";
import HeaderSwiper from "../headerSwiper/HeaderSwiper";

const Header = () => {
  return (
    <div className="header">
      <HeaderSwiper />
    </div>
  );
};

export default Header;
