import React from "react";

import Lists from "../lists/Lists";
import "./productHandler.scss";

const ProductsHandler = ({ lists }) => {
  return (
    <div className="serviceList">
      <div className="serviceList-container">
        <div className="lists">
          {lists.map((list) => (
            <Lists key={list.id} {...list} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductsHandler;
