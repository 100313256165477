import "./projects.scss";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import { ProductData } from "../../data/data";
import { galleryImages } from "../../data/data";
import { useEffect, useState } from "react";
import Modal from "../../ui/Modal";
import CompanyProjects from "../../components/companyProjects/CompanyProjects";
import Tabs from "../../components/tabs/Tabs";
import { Helmet } from "react-helmet";

const Projects = () => {
  const [tabValue, setTabValue] = useState("all");
  const [imageZoom, setImageZoom] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const tabsData = [
    { value: "all", title: "View All" },
    { value: "window", title: "Windows" },
    { value: "door", title: "doors" },
    { value: "pergola", title: "pergolas" },
    { value: "room", title: "Glass rooms/Garden rooms" },
    { value: "others", title: "others" },
  ];

  let images = Object.values(ProductData)
    .flat()
    .concat(galleryImages)
    .filter((el) => el.imagetype)
    .filter((item, i, arr) => {
      if (tabValue === "all") return arr;
      else return item.imagetype === tabValue;
    });
  const imageCount = images.length;
  const displayCount = 8;
  if (!loadMore) images = images.slice(0, displayCount);
  const handleLoadMore = () => {
    setLoadMore(!loadMore);
  };
  const handleClick = (e) => {
    setImageZoom(e);
  };
  useEffect(() => {
    setLoadMore(false);
  }, [tabValue]);

  return (
    <>
      <Helmet>
        <title>projects | Aspect Aluminium</title>
      </Helmet>
      {imageZoom && (
        <Modal setImageZoom={setImageZoom}>
          <img src={imageZoom} alt="" />
        </Modal>
      )}
      <SimpleHeader title={"Projects"} />
      <div className="gallery--container">
        <section>
          <CompanyProjects />
        </section>
        <section>
          <div className="heading">
            <h2>inspiration</h2>
            <div className="underline"></div>
          </div>

          <Tabs
            tabsData={tabsData}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
          <div className="gallery">
            <div className="images">
              {images.map((i) => (
                <div
                  className="img"
                  key={i.heading}
                  onClick={() => handleClick(i.img)}
                >
                  <div className="overlay"></div>
                  <img
                    src={i.img}
                    alt="something"
                    onClick={(e) => console.log(e.target.src)}
                  />
                  <h3 className="imageHeading">{i.heading}</h3>
                </div>
              ))}
            </div>
            {imageCount > displayCount && (
              <button className="btn" onClick={handleLoadMore}>
                {loadMore ? "View Less" : "View More"}
              </button>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Projects;
