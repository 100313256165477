import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Topbar from "../components/topbar/Topbar";
import { ParallaxProvider } from "react-scroll-parallax";
import Footer from "../components/footer/Footer";
import { DarkModeContext } from "../context/darkModeContext";
import { useContext } from "react";
import "../../src/darkmodeStyles/dark.scss";
import { MdWhatsapp } from "react-icons/md";
import ScrollToTop from "../ui/ScrollToTop";
import { FaArrowUp } from "react-icons/fa";
import Social from "../components/social/Social";

const RootLayout = () => {
  const { darkMode } = useContext(DarkModeContext);
  const [topButton, setTopButton] = useState(false);

  const showScrollButton = () => {
    if (window.scrollY > 700) {
      setTopButton(true);
    } else {
      setTopButton(false);
    }
  };

  const handleScrolltoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", showScrollButton);
  return (
    <div className={darkMode ? "dark" : ""}>
      <Social />
      <div className="sticky-whatsapp">
        <a href="https://wa.me/+971526139715" aria-label="whatsapp">
          <MdWhatsapp className="whatsapp-icon" />
        </a>
      </div>

      <button
        className={topButton ? "top__btn on" : "top__btn"}
        onClick={handleScrolltoTop}
        aria-label="Scroll to top"
      >
        <FaArrowUp className="top__btn--icon" />
      </button>

      <ParallaxProvider>
        <ScrollToTop />
        <Topbar />
        <Navbar />
        <Outlet />
        <Footer />
      </ParallaxProvider>
    </div>
  );
};

export default RootLayout;
