import React from "react";
import Contact from "../../components/contact/Contact";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";

const ContactPage = () => {
  return (
    <>
      <SimpleHeader title={"contact us"} />

      <Contact />
    </>
  );
};

export default ContactPage;
