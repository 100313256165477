import { Fragment } from "react";
import "./modal.scss";
import { MdClose } from "react-icons/md";
const Backdrop = ({ setImageZoom }) => {
  return <div className="backdrop" onClick={() => setImageZoom(false)} />;
};

const Modal = ({ setImageZoom, children }) => {
  return (
    <Fragment>
      <Backdrop setImageZoom={setImageZoom} />

      <div className="modal">
        <div className="close" onClick={() => setImageZoom(false)}>
          <MdClose />
        </div>

        {children}
      </div>
    </Fragment>
  );
};

export default Modal;
