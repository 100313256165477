export const images = {
  pergola: [
    {
      src: "/img/companyProjects/aluminum-pergola/20201226_171535.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/aluminum-pergola/20201228_132213.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/aluminum-pergola/20201228_132246.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/aluminum-pergola/20210820_162235.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/aluminum-pergola/20210820_162936.webp",
      width: 1,
      height: 1,
    },
    {
      src: "/img/companyProjects/aluminum-pergola/20211114_114340.webp",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/aluminum-pergola/20211114_114407.webp",
      width: 2.22,
      height: 1,
    },
  ],
  bath_partition: [
    {
      src: "/img/companyProjects/bathroom-glass-partitions/20210221_120348.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/bathroom-glass-partitions/20220921_170724.webp",
      width: 0.45,

      height: 1,
    },
    {
      src: "/img/companyProjects/bathroom-glass-partitions/20220921_171125.webp",
      width: 0.45,
      height: 1,
    },
    {
      src: "/img/companyProjects/bathroom-glass-partitions/20220921_171135.webp",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/bathroom-glass-partitions/20220921_171318.webp",
      width: 0.45,
      height: 1,
    },
    {
      src: "/img/companyProjects/bathroom-glass-partitions/IMG_20221228_104558_933.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/bathroom-glass-partitions/IMG_20221228_104559_105.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/bathroom-glass-partitions/IMG_20221228_104721_924.webp",
      width: 1,
      height: 1,
    },
    {
      src: "/img/companyProjects/bathroom-glass-partitions/IMG_20221228_104722_115.webp",
      width: 1.3,
      height: 1,
    },
  ],

  mirror: [
    {
      src: "/img/companyProjects/glass-and-mirrors-works/20211206_150115.jpg",
      width: 0.53,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-and-mirrors-works/20211206_150136.jpg",
      width: 0.68,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-and-mirrors-works/20220921_171335.jpg",
      width: 0.71,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-and-mirrors-works/IMG_20221228_104559_037.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-and-mirrors-works/IMG_20221228_104722_040.jpg",
      width: 0.8,
      height: 1,
    },
  ],
  handrail: [
    {
      src: "/img/companyProjects/glass-handrail/95582e14-792a-49fe-8f1c-fcb146ec1aa5.jpg",
      width: 0.6,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/20201018_123454.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/20210901_152406.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/20210901_152909.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/20210901_152959.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/20220311_144703.jpg",
      width: 0.45,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/20220311_144756.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/20220311_144807.jpg",
      width: 0.45,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/IMG_1731.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/IMG_1733.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/IMG_1734.jpg",
      alt: "IMG_1734.jpg",
      width: 0.75,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/IMG_20221026_142506_027.jpg",
      width: 0.8,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-handrail/IMG_20221026_142506_101.jpg",
      width: 0.8,
      height: 1,
    },
  ],

  glass_room: [
    {
      src: "/img/companyProjects/glass-room/1.jpg",
      width: 1.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/2.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/3.webp",
      width: 1,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/4.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/5.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/6.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/7.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/8.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/9.jpg",
      width: 2.2,
      height: 1,
    },

    {
      src: "/img/companyProjects/glass-room/10.jpg",
      width: 1.36,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/11.jpg",
      width: 1.36,
      height: 1,
    },
    {
      src: "/img/companyProjects/glass-room/12.jpg",
      width: 1.9,
      height: 1,
    },
  ],
  commercial: [
    {
      src: "/img/companyProjects/commercial/1.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/2.webp",
      width: 1,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/3.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/4.webp",
      width: 0.75,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/5.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/6.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/7.webp",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/8.webp",
      width: 1.9,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/9.webp",
      width: 1.9,
      height: 1,
    },
    {
      src: "/img/companyProjects/commercial/10.webp",
      width: 1.9,
      height: 1,
    },
  ],
  residential: [
    {
      src: "/img/companyProjects/residental/20201006_132700.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/20210607_150128.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/20210630_185100.jpg",
      width: 0.64,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/20210630_185310.jpg",
      width: 0.64,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/20210630_185713.jpg",
      width: 2.2,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/20210701_165438.jpg",
      width: 0.8,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/20210804_155349.jpg",
      width: 2,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1280.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1283.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1284.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1291.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1292.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1583.jpg",
      width: 0.75,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1584.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1586.jpg",
      width: 1.3,
      height: 1,
    },
    {
      src: "/img/companyProjects/residental/IMG_1587.webp",
      width: 1.3,
      height: 1,
    },
  ],
  videos: [
    {
      src: "/vids/palm_atlantis.mp4",
      width: 2.2,
      height: 1,
      type: 'video',
    },
    {
      src: "/vids/jumairah_park.mp4",
      width: 2.2,
      height: 1,
      type: 'video',
    },
    
  ],
};
