import { Link } from "react-router-dom";
import "./lists.scss";

const Lists = (list) => {
  return (
    <div
      className="allist"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-easing="linear"
    >
      <div className="img">
        <img src={list.img} alt={list.heading} />
      </div>

      <div className="content">
        <h3 className="heading-secondary">{list.heading}</h3>
        <p>{list.paragraph}</p>
        <Link to={list.link}>
         
          <button className="btn"> view more</button>
        </Link>
      </div>
    </div>
  );
};

export default Lists;
