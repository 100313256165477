import { createContext, useState } from "react";

export const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  return (
    <SearchContext.Provider
      value={{ searchResults, setSearchResults, searchKey, setSearchKey }}
    >
      {children}
    </SearchContext.Provider>
  );
};
