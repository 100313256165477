import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { SearchContextProvider } from "./context/SearchContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <SearchContextProvider>
      <DarkModeContextProvider>
        <App />
      </DarkModeContextProvider>
    </SearchContextProvider>
  </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement?.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//       <SearchContextProvider>
//         <DarkModeContextProvider>
//           <App />
//         </DarkModeContextProvider>
//       </SearchContextProvider>
//     </React.StrictMode>,
//     rootElement
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <SearchContextProvider>
//         <DarkModeContextProvider>
//           <App />
//         </DarkModeContextProvider>
//       </SearchContextProvider>
//     </React.StrictMode>,
//     rootElement
//   );
// }
