export const seo = [
  {
    id: "aluminium_windows",
    title: "Windows | Aluminium, Glass Upvc windows",
    description:
      "We are the leading aluminium and glass windows manufacturers in UAE. We supply all types of doors including upvc fixed windows, upvc tilt and turn windows, upvc sliding windows, aluminium windows, glass windows",
    keywords:
      "aluminium sliding windows, aluminium window design, aluminium windows and doors, aluminium window frames, upvc fixed windows, upvc tilt and turn windows, upvc sliding windows, aluminium windows, glass windows, aluminium windows glass louvers",
  },
  {
    id: "aluminium_doors",
    title: "Doors |  Aluminium, Glass Upvc Doors",
    description:
      "We are the leading aluminium and glass doors manufacturers in UAE. We supply all types of doors including upvc doors, cast aluminium doors, revolving doors, pivot doors, bi-folding doors, slide and fold doors",
    keywords:
      "aluminium doors, cast aluminium doors, glass doors, doors in uae, aluminium glass door, aluminium windows and doors, pivot doors, revolving doors, frameless doors",
  },
  {
    id: "aluminium_pergola",
    title: "Pergola in Dubai | UAE",
    description:
      "leading pergola construction and pergola contractors, suppliers in Dubai. we build all types of pergolas to match your space and your style",
    keywords:
      "pergola, modern pergola, aluminium pergola, contemporary pergola designs, modern aluminium pergola, modern pergola designs with glass, modern outdoor pergola, covered pergola, white pergola, outdoor space, seating areas, freestanding pergolas",
  },
  {
    id: "glass_partition",
    title: "Partition | office, home partitions in Dubai",
    description:
      "Top Glass partition suppliers in Dubai, Abudhabi and all over UAE. We provide best services to build office partition, Shower partition/bathroom partition ",
    keywords:
      "Glass Partition, shower glass partition, glass partition dubai, bathroom glass partition, glass office partitions, glass partition wall, glass partition design, glass partition suppliers, office room glass partition wall, shower glass partition abudhabi, partition walls",
  },
  {
    id: "bifolding_doors",
    title: "Bifold Doors in UAE",
    description:
      "Transform your space with styish and functional and space saving custom bifold doors made out of aluminium, glass available in 2 to 8 folds. we also specialized in sliding pocket door, roll up garage doors ",
    keywords:
      "bifold doors, folding doors dubai, frameless bifold external doors, folding doors, folding panel doors, foldable glass doors, internal bifold doors, automatic bifold garage doors, bifold closet doors, glass bifold doors, interior folding doors, exterior folding doors, contemporary folding doors, garage doors, roll up garage doors, sliding pocket door",
  },
  {
    id: "cast_aluminium_doors",
    title: "Cast Aluminium Doors UAE",
    description:
      "Discover the timeless elegance and durability of cast aluminium doors and gates, enhancing your property with style, security, and lasting quality.",
    keywords:
      "Cast aluminium entry doors, aluminium gate designs, custom cast aluminium gates, durable aluminium doors, ornamental aluminium gates, decorative aluminium gates, aluminium security doors, powder coated aluminium gates, aluminium garden gates, weather-resistant cast aluminium doors, modern aluminium gates, long lasting aluminium gates, stylish aluminium entrance doors, louvre doors, aluminium louvre doors",
  },
  {
    id: "aluminium_staircase",
    title: "Aluminium Staircase Dubai, UAE",
    description:
      "A lightweight and durable solution for stylish and functional vertical access. Aspect Aluminium provides best spiral Aluminium staircase, round aluminium staircase that suits you space perfectly.",
    keywords:
      "staircase, staircase design, spiral staircase, stell staircase, glass staircase, glass stair railing, modern stairs railing design in steel, small space stairs, round staircase, modern steel stair, aluminium stairs, aluminium staircase railing",
  },
  {
    id: "aluminium_composite_cladding",
    title: "Aluminium Cladding in Dubai",
    description:
      "Aluminium cladding: A versatile and sustainable exterior solution, combining aesthetics and protection. Discover the transformative power of aluminium cladding for your building's exterior - combining durability, style, and energy efficiency.",
    keywords:
      "aluminium cladding, aluminium cladding sheet, composite cladding exterior, aluminium cladding rails, grey aluminium cladding, interior aluminium cladding, aluminium cladding work, aluminium cladding UAE",
  },
  {
    id: "aluminium_louver",
    title: "Aluminium Louver Dubai, UAE",
    description:
      "Enhance your space with aluminium louvers, offering efficient airflow, privacy control, and contemporary design. Discover the benefits of versatile aluminium louvers for your building or architectural project.",
    keywords:
      "aluminium louver door, louvers, aluminium louvers, aluminium louver manufacturers, exterior aluminium louvers, aluminium louvered pergola, architectural louvers, airflow control, privacy control, ventilation solution, contemporary design, building louvers, louver systems, exterior louvers, sun control, weather-resistant, energy-efficient, versatile louvers, metal louvers, aluminium facade, building envelope, louvers for windows, louvers for ventilation",
  },
  {
    id: "aluminium_mashrabiya",
    title: "Aluminium Mashrabiya - Dubai",
    description:
      "Discover the beauty of aluminium mashrabiya: intricate Arabesque patterns combined with modern design, creating stunning decorative metal screens for contemporary architecture.",
    keywords:
      "mashrabiya, mashrabiya design, aluminium mashrabiya, aluminium mashrabiya screens, مشربية, mashrabiya UAE, mashrabiya pattern, mashrabiya lounge, aluminium screen, modern mashrabiya, traditional middle eastern architecture, mashrabiya windows, decorative metal screens, ornamental metalwork ",
  },
  {
    id: "shower_glass",
    title: "Shower Glass partition in Dubai, UAE",
    description:
      "Find top-quality shower glass solutions in the UAE. Explore a wide range of stylish and durable shower glass enclosures, doors, and partitions to transform your bathroom. Discover expert craftsmanship, innovative designs, and exceptional service from leading shower glass providers in the UAE.",
    keywords:
      "shower glass,Bathtub with Glass Wall, shower glass partition, glass shower doors, bathroom glass partition, shower doors, shower partition, bathroom glass doors, bath glass partition, bathroom partitions, bathroom shower doors, shower glass partition Abudhabi, cheap glass shower enclosures, bathroom partitions wall,  bathroom enclosure, shower room divider, glass shower wall",
  },
  {
    id: "curtain_walls",
    title: "Curtain Walls - UAE",
    description:
      "Explore the versatility and innovation of curtain walls in modern architecture. Discover their benefits, types, materials, and applications in creating stunning building exteriors. Enhance aesthetics, energy-efficiency, and sustainability with state-of-the-art curtain wall systems.",
    keywords:
      "curtain walls, glass facade, building envelope, glass wall, curtain wall windows, glass curtain wall,curtain wall details, curtain wall materials, aluminium curtain wall systems, curtain wall thermal break, types of curtain wall systems, single glazed curtain walls",
  },
  {
    id: "sliding_glass_doors",
    title: "Sliding Glass doors, frameless glass doors, revolving doors in UAE",
    description:
      "Discover sleek and contemporary door solutions in Dubai. Explore the elegance and functionality of sliding glass doors, revolving doors, and frameless doors. Enhance your space with modern designs and enjoy seamless transitions between indoor and outdoor areas. Experience the luxury and innovation of premium door systems in Dubai's architectural landscape.",
    keywords:
      "sliding glass door, revolving door, aluminium glass door, frameless glass door, sliding glass door lock, sliding glass door design, full wall sliding glass doors, clear glass door, revolving door suppliers in Dubai, revolving glass door",
  },
  {
    id: "glass_railing",
    title: "Handrail, balustrade in UAE",
    description:
      "Enhance your space with stunning balustrades and elegant glass stair railings, combining safety and sophistication seamlessly.",
    keywords:
      "balustrades, glass railing, glass handrail, stairway glass, guard railing, glass stair railing, glass balustrade, balcony glass railing, rigid glass railing, frameless glass balustrade fittings, glass balustrade with stainless steel handrail ",
  },
  {
    id: "upvc_sliding_windows",
    title: "UPVC sliding windows in UAE",
    description:
      "Transform your space with our premium UPVC and aluminium sliding windows. Experience innovative designs, superior materials, and reliable window parts for the ultimate combination of style and functionality.",
    keywords:
      "upvc window, aluminium upvc windows, sliding window, sliding window design, aluminium sliding windows, sliding window lock, aluminium sliding window parts, aluminium sliding window material, upvc sliding windows ",
  },
  {
    id: "upvc_fixed_windows",
    title: "Fixed Glass, UPVC windows in Dubai ",
    description:
      "Durable UPVC fixed windows with enhanced energy efficiency. Enjoy unobstructed views and abundant natural light. Upgrade your home with elegant, maintenance-free frames.",
    keywords:
      "upvc window, aluminium upvc windows, aluminium fixed windows, upvc fixed windows, aluminium frame fixed windows, fixed frame windows",
  },
  {
    id: "upvc_tilt_and_windows",
    title: "Tilt and Turn Windows in Dubai",
    description:
      "Transform your living space in Dubai with versatile tilt and turn windows. Experience seamless ventilation, easy cleaning, and enhanced security. Discover the perfect combination of style and functionality for your home or business.",
    keywords:
      "upvc windows, aluminium upvc window, upvc tilt and turn windows, tilt and turn windows, tilt and turn window handles, aluminium tilt and turn windows",
  },
  {
    id: "upvc_doors",
    title: "upvc doors and windows manufacturers in uae",
    description:
      "Aspect Aluminium offers premium UPVC doors that seamlessly combine durability, style, and energy efficiency, enhancing both security and aesthetic appeal for your residential or commercial space.",
    keywords:
      "upvc, upvc doors, upvc windows and doors, buy upvc doors, upvc doors and windows manufacturer in UAE, upvc front doors price, upvc doors price, coloured upvc front doors, ابواب upvc, upvc ابواب",
  },
];
