import { ParallaxBanner } from "react-scroll-parallax";
import "./bgSection.scss";

const BgSection = () => {
  return (
    <ParallaxBanner
      layers={[{ image: "/img/header/bgSection.jpg", speed: -30 }]}
      className="aspect-[2/1]"
    >
      <div className="bgSection">
        <div className="bgContainer">
          <div
            className="bgContent"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <h2 className="heading-secondary">
              Aluminium Doors, Residential & Commercial Installations Of High
              Quality aluminium, Pivot, Folding & Revolving Doors
            </h2>
            <p>
              Interior & Exteriors are an important element in the finishing and
              are made to give a profound space esthetics to your space. We at
              Aspect provide concepts best suited to your space and enhance the
              beauty & design. Types of doors we fabricate & Install are:
            </p>
            <div className="listContainer">
              <ul className="lists">
                <li>⚪ BI Folding Doors</li>
                <li>⚪ Aluminium / Glass Swing Doors</li>
                <li>⚪ Louvers Design Doors And Shutters</li>
                <li>⚪ Flush Doors</li>
                <li>⚪ Glassed aluminium Door</li>
                <li>⚪ Office Glass & aluminium Partitions</li>
                <li>⚪ Shop Fronts</li>
                <li>⚪ Curtain Walls</li>
              </ul>
              <ul className="lists">
                <li>⚪ Revolving Doors</li>
                <li>⚪ Sliding Doors & Glass Partitions</li>
                <li>⚪ SkyLight</li>
                <li>⚪ Mirrors</li>
                <li>⚪ Balcony Glass Balustrades & Railings</li>
                <li>⚪ Glass Shower Partitions & Doors</li>
                <li>⚪ Screening & Ventilation Louvres</li>
                <li>⚪ Fly Screen Doors</li>
              </ul>
            </div>
          </div>
          <div
            className="partner--wrapper"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <div className="partner">
              <div className="heading">
                <h2>OUR PARTNERS</h2>
                <div className="underline"></div>
              </div>

              <div className="img">
                <img src="/img/partners.png" alt="partners" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ParallaxBanner>
  );
};

export default BgSection;
