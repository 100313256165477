import { useCallback, useState } from "react";
import "./companyProjects.scss";
import Tabs from "../tabs/Tabs";
import Gallery from "react-photo-gallery";
import { images } from "./images";
import Modal from "../../ui/Modal";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import { FaPlay } from 'react-icons/fa'; 
const tabsData = [
  { value: "pergola", title: "aluminium pergola" },
  { value: "bath_partition", title: "bathroom glass partitions" },
  { value: "mirror", title: "glass and mirror works" },
  { value: "handrail", title: "glass handrail" },
  { value: "glass_room", title: "glass room and Dome" },
  { value: "commercial", title: "government and commercial" },
  { value: "residential", title: "residential" },
  { value: "videos", title: "videos" },
];

const CompanyProjects = () => {
  const [tabValue, setTabValue] = useState("pergola");
  const [openModal, setOpenModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [disableLeft, setDisableLeft] = useState(false);
  const [disableRight, setDisableRight] = useState(false);
  

  const photos = images[tabValue];
  
  const handleLightBox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setOpenModal(true);
  }, []);

  useEffect(() => {
    currentImage <= 0 ? setDisableLeft(true) : setDisableLeft(false);
    currentImage === photos.length - 1
      ? setDisableRight(true)
      : setDisableRight(false);
  }, [currentImage, photos.length]);

  const swipeLeft = () => {
    setCurrentImage((cur) => {
      return cur <= 0 ? cur : cur - 1;
    });
  };
  const swipeRight = () => {
    setCurrentImage((cur) => {
      return cur >= photos.length - 1 ? cur : cur + 1;
    });
  };

  const filteredPhotos = photos.filter(photo => tabValue !== 'video' || photo.type === 'video');

  return (
    <>
      <div className="heading">
        <h2>Our Works</h2>
        <div className="underline"></div>
      </div>

      <Tabs tabsData={tabsData} tabValue={tabValue} setTabValue={setTabValue} />
      <div className="photo--gallery">
        <Gallery
          photos={filteredPhotos}
          className="images"
          margin={10}
          onClick={handleLightBox}
          renderImage={(props) => {
            const { photo } = props;
            if (photo.type === "video") {
              return (
                <div
                  style={{
                    width: photo.width,
                    margin: '10px',
                    cursor: "pointer",
                    position: 'relative'
                  }}
                  onClick={(event) => props.onClick(event, props)}
                >
                  <ReactPlayer url={photo.src} width='100%' height='100%' />
                  <FaPlay style={{ // Add this to display the play icon
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '6em',
            color: '#052190d6',
            textShadow: '0 0 10px rgba(0, 0, 0, 0.6)'
          }} />
                </div>
              );
            }

            // Default image rendering
            return (
              <img
                {...photo} style={{ margin: '10px' }}
                onClick={(event) => props.onClick(event, props)}
                alt={`photos ${currentImage}`}
              />
            );
          }}
        />
      </div>
      {openModal && (
        <>
          <button disabled={disableLeft} className="arrow arrow--left">
            <MdOutlineArrowBackIos className="icon" onClick={swipeLeft} />
          </button>
          <button disabled={disableRight} className="arrow arrow--right">
            <MdOutlineArrowForwardIos className="icon" onClick={swipeRight} />
          </button>
          <Modal setImageZoom={setOpenModal}>
            {photos[currentImage].type === "video" ? (
              <video 
              controls autoplay
              src={photos[currentImage].src}
              alt={`photos ${currentImage}`}
              />
            ) : (
              <img 
              src={photos[currentImage].src}
              alt={`photos ${currentImage}`}
              />
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default CompanyProjects;
