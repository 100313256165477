import "./suppliers.scss";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

import { Autoplay, FreeMode, Navigation } from "swiper";

const Suppliers = () => {
  return (
    <div className="suppliers">
      <div className="suppliers--container">
        {/* <h4 className="tag">Our Suppliers</h4> */}
        <div className="heading">
          <h2>OUR SUPPLIERS:</h2>
          <div className="underline"></div>
        </div>
        <div className="supplier--slider">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            freeMode={true}
            breakpoints={{
              550: {
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 3,
              },
            }}
            modules={[FreeMode, Navigation, Autoplay]}
            loop
            className="supplier--swiper"
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <img src="/img/suppliers/1.png" alt="gutmann" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/2.png" alt="gulf glass industries" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/3.png" alt="guardian glass" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/4.png" alt="geze" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/5.png" alt="aluminium installux" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/6.png" alt="arexco" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/7.png" alt="emirates glass" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/8.png" alt="giesse" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/9.png" alt="gulf extrusions" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/img/suppliers/10.png" alt="master" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
