import { Button, Form, Input } from "antd";
import React, { useContext } from "react";
import FormContext from "../../context/MultiStepFormContext";

const Description = () => {
  const { formDetails, setFormDetails, next, prev } = useContext(FormContext);
  const { TextArea } = Input;
  const handleNextClicks = () => {
    next();
  };

  return (
    <div className="field description--wrapper">
      <Form className="form--container" onFinish={handleNextClicks}>
        <div className="form--group">
          <Form.Item
            name="description"
            label="Details"
            required={true}
            rules={[{ required: true, message: "Please Write Some details!" }]}
            initialValue={formDetails.description}
          >
            <TextArea
              name="description"
              id="description"
              rows={4}
              placeholder="(share your project details or buying list to get quotation)"
              onChange={(e) =>
                setFormDetails({
                  ...formDetails,
                  description: e.target.value,
                })
              }
            />
          </Form.Item>
        </div>

        <Form.Item>
          <div className="buttons">
            <Button type={"default"} onClick={prev}>
              Back
            </Button>
            <Button type={"primary"} htmlType="submit">
              Next
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Description;
