import CareerList from "../../components/careersData/careerList/CareerList";

import SimpleHeader from "../../components/simpleHeader/SimpleHeader";

const Careers = () => {
  return (
    <>
      <SimpleHeader title="Careers" />
      <CareerList />
    </>
  );
};

export default Careers;
