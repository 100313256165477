import "./tabs.scss";

const Tabs = ({ tabsData, tabValue, setTabValue }) => {
  return (
    <div className="tabs">
      {tabsData.map((tab) => (
        <h4
          key={tab.value}
          className={tabValue === tab.value ? "tab active" : "tab"}
          onClick={() => setTabValue(tab.value)}
        >
          {tab.title}
        </h4>
      ))}
    </div>
  );
};

export default Tabs;
