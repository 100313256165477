import { useNavigate, useParams } from "react-router-dom";
import "./product.scss";
import { ProductData } from "../../data/data";
import { useEffect } from "react";
import ProductHeader from "../../components/productHeader/ProductHeader";
import { Helmet } from "react-helmet";
import { seo } from "../../seo";

const Product = () => {
  const product = useParams().product;
  const metaTagsData = seo.filter((el) => el.id === product);

  const navigate = useNavigate();
  useEffect(() => {
    if (!ProductData[product]) {
      return navigate("/");
    }
  }, [product, navigate]);
  //   console.log(ProductData[product]);

  return (
    <>
      <ProductHeader title={product} />
      {metaTagsData.length === 1 && (
        <Helmet>
          <title>{`${metaTagsData[0].title} | Aspect Aluminium`}</title>
          <meta name="description" content={metaTagsData[0].description} />
          <meta name="keywords" content={metaTagsData[0].keywords} />
        </Helmet>
      )}

      {ProductData[product]?.map((p) => (
        <div className="products-container" key={p.id} {...p}>
          <div className={p.id % 2 ? "products" : "products direction"}>
            <div className="products_img">
              <div
                className="img"
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-once="true"
              >
                <img src={p.img} alt={p.heading} />
              </div>
            </div>

            <div className="product_details">
              <h2 className="heading-secondary">{p.heading}</h2>
              <p>{p.paragraph}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Product;
