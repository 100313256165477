// import React, { useContext, useState } from "react";
// import "./search.scss";
// import { FaSearch } from "react-icons/fa";
// import { products } from "../../data/data";
// import { useNavigate } from "react-router-dom";
// import { SearchContext } from "../../context/SearchContext";
// import { MdClose, MdWarning } from "react-icons/md";
// import { useEffect } from "react";

// const Search = () => {
//   const navigate = useNavigate();
//   const { setSearchKey, setSearchResults } = useContext(SearchContext);
//   const titles = products.map((el) => el.heading.toLowerCase());
//   // console.log(titles);
//   const [searchValue, setSearchValue] = useState("");
//   const [alert, setAlert] = useState(false);

//   const handleInputChange = (e) => {
//     setSearchValue(e.target.value.toLowerCase());
//   };
//   const handleSearch = () => {
//     setSearchResults(filteredValue);
//     setSearchKey(searchValue);
//     setSearchValue("");
//     if (!searchValue) {
//       setAlert(true);
//       return;
//     }
//     navigate("search-results");
//   };

//   const handleKeys = (e) => {
//     switch (e.keyCode) {
//       case 13: {
//         handleSearch();
//         return;
//       }

//       case 40: {
//         console.log("down key");

//         break;
//       }
//       case 38: {
//         console.log("up key");
//         break;
//       }
//       default:
//     }

//     // console.log(e);
//   };

//   const filteredValue = titles.filter(
//     (item) => searchValue && item.includes(searchValue)
//   );

//   const showValue = filteredValue.filter((e) => e !== searchValue);

//   useEffect(() => {
//     setTimeout(() => {
//       setAlert(false);
//     }, 5000);
//   });
//   return (
//     <div className="search">
//       <div className="search--box">
//         <input
//           type="text"
//           placeholder="Find Our products..."
//           onChange={handleInputChange}
//           value={searchValue}
//           onFocus={() => setAlert(false)}
//           onKeyDown={handleKeys}
//         />
//         {searchValue && (
//           <MdClose className="searchClear" onClick={() => setSearchValue("")} />
//         )}

//         <button className="searchButton" onClick={handleSearch}>
//           Search <FaSearch className="searchIcon" />
//         </button>
//       </div>
//       {alert && (
//         <div className="alertBox">
//           Search String Cannot be Empty
//           <MdWarning className="warning--icon" />
//         </div>
//       )}

//       <div
//         className={
//           showValue.length === 0
//             ? "search--dropdown__empty"
//             : "search--dropdown"
//         }
//       >
//         {showValue.map((e, i) => (
//           <span onClick={() => setSearchValue(e)} key={i}>
//             {e.toUpperCase()}
//           </span>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Search;

import React, { useContext, useState } from "react";
import "./search.scss";
import { FaSearch } from "react-icons/fa";
import { products } from "../../data/data";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../context/SearchContext";
import { MdClose, MdWarning } from "react-icons/md";
import { useEffect } from "react";

const Search = () => {
  const navigate = useNavigate();
  const { setSearchKey, setSearchResults } = useContext(SearchContext);
  const [count, setCount] = useState(-1);
  const [keyPressed, setKeyPressed] = useState();
  const titles = products.map((el) => el.heading.toLowerCase());
  // console.log(titles);
  const [searchValue, setSearchValue] = useState("");
  const [alert, setAlert] = useState(false);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };
  const handleSearch = () => {
    setSearchResults(
      keyPressed ? filteredValue(keyPressed) : filteredValue(searchValue)
    );
    keyPressed ? setSearchKey(keyPressed) : setSearchKey(searchValue);

    setSearchValue("");
    if (!searchValue) {
      setAlert(true);
      return;
    }
    navigate("search-results");
  };

  const filteredValue = (value) =>
    titles.filter((item) => value && item.includes(value));

  const showValue = filteredValue(searchValue).filter((e) => e !== searchValue);
  useEffect(() => {
    setKeyPressed(showValue[count]);
  }, [showValue, count]);

  useEffect(() => {
    if (searchValue === "") setCount(-1);
  }, [searchValue]);

  const handleKeys = (e) => {
    switch (e.keyCode) {
      case 13: {
        handleSearch();
        return;
      }

      case 40: {
        if (count >= showValue.length - 1) return;

        setCount((prev) => prev + 1);

        break;
      }
      case 38: {
        if (count <= 0) return;

        setCount((prev) => prev - 1);

        break;
      }
      default:
    }

    // console.log(e);
  };
  // console.log(keyPressed);
  // console.log(count);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 5000);
  });
  return (
    <div className="search">
      <div className="search--box">
        <input
          type="text"
          placeholder="Find Our products..."
          onChange={handleInputChange}
          value={searchValue}
          onFocus={() => setAlert(false)}
          onKeyDown={handleKeys}
        />
        {searchValue && (
          <MdClose className="searchClear" onClick={() => setSearchValue("")} />
        )}

        <button className="searchButton" onClick={handleSearch}>
          Search <FaSearch className="searchIcon" />
        </button>
      </div>
      {alert && (
        <div className="alertBox">
          Search String Cannot be Empty
          <MdWarning className="warning--icon" />
        </div>
      )}

      <div
        className={
          showValue.length === 0
            ? "search--dropdown__empty"
            : "search--dropdown"
        }
      >
        {showValue.map((e, i) => (
          <span
            className={e === keyPressed ? "active" : ""}
            onClick={() => setSearchValue(e)}
            key={i}
          >
            {e.toUpperCase()}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Search;
