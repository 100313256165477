import { cardsData } from "../../data/data";
import Cards from "../cards/Cards";
import "./service.scss";

const Service = () => {
  return (
    <div className="service">
      <div id="service" className="service--container">
        <div className="heading">
          <h2>our services</h2>
          <div className="underline"></div>
        </div>

        <div className="cards">
          {cardsData.map((card) => (
            <Cards key={card.id} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service;
