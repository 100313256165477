import "./quotation.scss";
import React, { useState } from "react";
import { Steps } from "antd";
import { Provider } from "../../context/MultiStepFormContext";
import ContactInfo from "../../components/MultiStepForm/ContactInfo";
import Type from "../../components/MultiStepForm/Type";
import Measure from "../../components/MultiStepForm/Measure";
import Description from "../../components/MultiStepForm/Description";
import Review from "../../components/MultiStepForm/Review";

const initialFormDetails = {
  type: "",
  gridV: "",
  gridH: "",
  height: "",
  width: "",
  description: "",
  name: "",
  phone: "",
  email: "",
};

const renderStep = (step) => {
  switch (step) {
    case 0:
      return <Type />;

    case 1:
      return <Measure />;

    case 2:
      return <Description />;
    case 3:
      return <ContactInfo />;

    case 4:
      return <Review />;
    default:
      return null;
  }
};
const items = [
  {
    title: "Choose Your Product",
  },
  {
    title: "Details",
  },
  {
    title: "Description",
  },
  {
    title: "Contact Info",
  },
  {
    title: "Review",
  },
];

const Quotation = () => {
  const [formDetails, setFormDetails] = useState(initialFormDetails);
  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    if (currentStep === 4) {
      setCurrentStep(0);

      setFormDetails(initialFormDetails);
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const prev = () => setCurrentStep(currentStep - 1);

  return (
    <div className="quotation">
      <div className="quotation--container">
        <Provider
          value={{
            next,
            prev,
            setFormDetails,
            formDetails,
          }}
        >
          <Steps current={currentStep} className="steps" items={items} />

          <main>{renderStep(currentStep)}</main>
        </Provider>
      </div>
    </div>
  );
};

export default Quotation;
