import { Link } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="row">
          <div className="col">
            <Link to={"/products/aluminium"}>
              <h3 className="sub-heading">ALUMINIUM</h3>
            </Link>

            <ul>
              <li>
                <Link to="/products/aluminium/aluminium_doors">
                  Aluminium Doors
                </Link>
              </li>
              <li>
                <Link to="/products/aluminium/aluminium_windows">
                  Aluminium Windows
                </Link>
              </li>
              <li>
                <Link to="/products/aluminium/aluminium_pergola">
                  Aluminium Pergola
                </Link>
              </li>
              <li>
                <Link to="/products/aluminium/bifolding_doors">
                  Bi-Folding Doors
                </Link>
              </li>
              <li>
                <Link to="/products/aluminium/cast_aluminium_doors">
                  Cast Aluminium Doors
                </Link>
              </li>
              <li>
                <Link to="/products/aluminium/aluminium_staircase">
                  Aluminium Staircase
                </Link>
              </li>
              <li>
                <Link to="/products/aluminium/aluminium_composite_cladding">
                  Aluminium composite Cladding
                </Link>
              </li>
              <li>
                <Link to="/products/aluminium/aluminium_louver">
                  Aluminium Louver
                </Link>
              </li>
              <li>
                <Link to="/products/aluminium/aluminium_mashrabiya">
                  Aluminium Mashrabiya
                </Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <Link to={"/products/glass"}>
              <h3 className="sub-heading">GLASS</h3>
            </Link>

            <ul>
              <li>
                <Link to="/products/glass/glass_partition">
                  Glass Partition
                </Link>
              </li>
              <li>
                <Link to="/products/glass/shower_glass">Shower Glass</Link>
              </li>
              <li>
                <Link to="/products/glass/sliding_glass_doors">
                  Sliding Glass Door
                </Link>
              </li>
              <li>
                <Link to="/products/glass/glass_railing">Glass Railing</Link>
              </li>
              <li>
                <Link to="/products/glass/curtain_walls">Curtain Wall</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <Link to={"/products/upvc"}>
              <h3 className="sub-heading">UPVC</h3>
            </Link>

            <ul>
              <li>
                <Link to="/products/upvc/upvc_doors">UPVC Doors</Link>
              </li>
              {/* <li>
                <Link to="/products/upvc/upvc_doors">UPVC Sliding Doors</Link>
              </li> */}
              <li>
                <Link to="/products/upvc/upvc_sliding_windows">
                  UPVC Sliding Windows
                </Link>
              </li>
              <li>
                <Link to="/products/upvc/upvc_fixed_windows">
                  UPVC Fixed Window
                </Link>
              </li>
              <li>
                <Link to="/products/upvc/upvc_tilt_and_turn_windows">
                  UPVC Tilt & Turn Windows
                </Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3 className="sub-heading" onClick={() => window.scrollTo(0, 0)}>
              ABOUT US
            </h3>
            <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact-Us</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
          </div>
        </div>
        <h3 className="sub-heading">
          Copyright © 2023. Aspect Aluminium and Glass Industry LLC.
        </h3>
      </div>
    </div>
  );
};

export default Footer;
