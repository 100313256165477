import { Button, Form } from "antd";
import FormContext from "../../context/MultiStepFormContext";
import React, { useContext } from "react";

const ContactInfo = () => {
  const { formDetails, setFormDetails, next, prev } = useContext(FormContext);
  const handleNextClick = () => {
    next();
  };
  return (
    <div className="field details--wrapper">
      <Form onFinish={handleNextClick} className="form--container">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please Enter Your Name" }]}
          initialValue={formDetails.name}
        >
          <div className="form--group">
            <input
              type="text"
              value={formDetails.name}
              name="name"
              onChange={(e) =>
                setFormDetails({ ...formDetails, name: e.target.value })
              }
            />
          </div>
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please Enter Your Email" }]}
          initialValue={formDetails.email}
        >
          <div className="form--group">
            <input
              type="email"
              value={formDetails.email}
              name="email"
              onChange={(e) =>
                setFormDetails({ ...formDetails, email: e.target.value })
              }
            />
          </div>
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          initialValue={formDetails.phone}
          rules={[
            { required: true, message: "Please Enter Your Contact Number" },
            {
              pattern: /^(?:\d*)$/,
              message: "Value should contain just number",
            },
          ]}
        >
          <div className="form--group">
            <input
              type="tel"
              value={formDetails.phone}
              name="name"
              onChange={(e) =>
                setFormDetails({ ...formDetails, phone: e.target.value })
              }
            />
          </div>
        </Form.Item>
        <div className="buttons">
          <Button type={"default"} onClick={prev}>
            Back
          </Button>
          <Button type={"primary"} htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ContactInfo;
