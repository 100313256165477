export const cardsData = [
  {
    id: 1,
    img: "/img/cards/card-1.jpg",
    heading: "Aluminium Pergola",
    paragraph:
      "Aluminium Pergola manufactured at Aspect in Dubai is the best place to get Pergola",
    link: "/products/aluminium/aluminium_pergola",
  },
  {
    id: 2,
    img: "/img/cards/card-2.jpg",
    heading: "Bi-Folding Doors",
    paragraph:
      "Bifold Doors that are made of Aluminium are the most strong, functional, and secure doors.",
    link: "/products/aluminium/bifolding_doors",
  },
  {
    id: 3,
    img: "/img/cards/card-3.jpg",
    heading: "Aluminium Doors",
    paragraph:
      "Interior Doors are the most important element in the interior decoration of space.",
    link: "/products/aluminium/aluminium_doors",
  },
  {
    id: 4,
    img: "/img/cards/card-4.jpg",
    heading: "Aluminium Windows",
    paragraph:
      "Windows are vital for the functionality of our spaces .They provide an aesthetic view and brightning light",
    link: "/products/aluminium/aluminium_windows",
  },

  {
    id: 5,
    img: "/img/cards/card-5.jpg",
    heading: "Shower Glass",
    paragraph:
      "Shower Glass is a very trendy interior used nowadays in every home to showcase a fancy look.",
    link: "/products/glass/shower_glass",
  },
  {
    id: 6,
    img: "/img/cards/card-6.jpg",
    heading: "Sliding Glass Doors",
    paragraph:
      "Glass Door Company with options such as sliding Glass Door replacement. They provide a modern look to your home.",
    link: "/products/glass/sliding_glass_doors",
  },
  {
    id: 7,
    img: "/img/cards/card-7.jpg",
    heading: "Glass Railing",
    paragraph:
      "Glass railings aren’t something that everyone can handle as they are fragile.They must be handled with care",
    link: "/products/glass/glass_railing",
  },
  {
    id: 8,
    img: "/img/cards/card-8.jpg",
    heading: "Curtain Walls",
    paragraph:
      "Curtain Walls in different shapes and various color options that are the worst weather resistant.",
    link: "/products/glass/curtain_walls",
  },
];

export const products = [
  {
    id: 1,
    img: "/img/projects/partitions/partition--thumb.jpg",
    heading: "Glass Partition",
    paragraph:
      "Glass partition which is the feature of interior design creates an aesthetically attractive pleasing environment which gives a tasteful impression be it at an office or even at home.",

    link: "/products/glass/glass_partition",
    material: "glass",
  },

  {
    id: 2,
    img: "/img/showerGlass.jpg",
    heading: "Shower Glass",
    paragraph:
      "Shower Glass is a very trendy interior used nowadays in every home to showcase a fancy look. It has become a new modernized trend.",

    link: "/products/glass/shower_glass",
    material: "glass",
  },
  {
    id: 3,
    img: "/img/projects/doors/glass/glass-doors-thumb.jpg",
    heading: "Sliding Glass Doors",
    paragraph:
      "Dubai at Aspect is the best Glass Door Company with options such as sliding Glass Door replacement, sliding folding door, sliding patio door, automatic sliding door and many others.",

    link: "/products/glass/sliding_glass_doors",
    material: "glass",

    type: "doors",
  },
  {
    id: 4,
    img: "/img/projects/railing/glassRailing.jpg",
    heading: "Glass Railing",
    paragraph:
      "Draw out the view of your home with our unbeatable quality of glass railings which helps to enhance your living space.",

    link: "/products/glass/glass_railing",
    material: "glass",
  },
  {
    id: 5,
    img: "/img/cards/card-8.jpg",
    heading: "Curtain Walls",
    paragraph:
      "Aspect offers a variety of Curtain Walls in different shapes, depths, finishes, and various color options that are the worst weather resistant.",

    link: "/products/glass/curtain_walls",
    material: "glass",
  },

  {
    id: 6,
    img: "/img/projects/doors/aluminum/aluminum--thumb.jpg",
    heading: "Aluminium Doors",
    paragraph:
      "Interior Doors are the most important element in the interior decoration of space which must be made ideal to give a profound effect, and volatizing description to your space.",

    link: "/products/aluminium/aluminium_doors",
    material: "aluminium",
    type: "doors",
  },
  {
    id: 7,
    img: "/img/projects/windows/aluminum/aluminum-windows-thumb.jpg",
    heading: "Aluminium Windows",
    paragraph:
      "Windows are vital for the functionality of our spaces as they help up with the ventilation hence provide a view too. They provide us with an aesthetic view and brightening light.",

    link: "/products/aluminium/aluminium_windows",
    material: "aluminium",
    type: "windows",
  },
  {
    id: 8,
    img: "/img/projects/pergolas/pergola--thumb.jpg",
    heading: "Aluminium Pergola",
    paragraph:
      "Aluminium Pergola manufacturers in Dubai is the best place to get Pergola for your space and give your space a volume and sense of style to make your day and environment better",

    link: "/products/aluminium/aluminium_pergola",
    material: "aluminium",
  },
  {
    id: 9,
    img: "/img/projects/bi-fold/bifold-doors.jpg",
    heading: "Bi-Folding Doors",
    paragraph:
      "Bifold Doors that are made of aluminium are the most strong, functional, and secure doors. Bi-fold engineered doors are available in 2 to 8 folds, even more than that in different designs depending on the customer’s choice.",

    link: "/products/aluminium/bifolding_doors",
    material: "aluminium",
    type: "doors",
  },
  {
    id: 10,
    img: "/img/castAluminiumDoors.jpg",
    heading: "Cast Aluminium Doors",
    paragraph:
      "We take great pleasure in introducing our all products as we are confident about the quality we supply to our clients. We are this successful because of the high-quality products we provide under an affordable budget.",

    link: "/products/aluminium/cast_aluminium_doors",
    material: "aluminium",
    type: "doors",
  },
  {
    id: 11,
    img: "/img/aluminiumStaircase.jpg",
    heading: "Aluminium Staircase",
    paragraph:
      "Staircases nowadays have become an important part of the architecture world. Stairs are the focal point of a space due to which it is very necessary to install yourself a great design of stairs that are durable and minimal.",

    link: "/products/aluminium/aluminium_staircase",
    material: "aluminium",
  },

  {
    id: 12,
    img: "/img/aluminium-composite-cladding.jpeg",
    heading: "Aluminium composite Cladding",
    paragraph:
      "Aluminium composite cladding system or ACP is typically a metal curtain wall system. ACP is made from lightweight aluminium with hard insulation. Aluminium composite cladding is used for exterior cladding, column covers, fascia’s and canopies, interior wall and partition panels, parapets, louvers and sunshades.",

    link: "/products/aluminium/aluminium_composite_cladding",
    material: "aluminium",
  },
  {
    id: 13,
    img: "/img/aluminium-louver.jpeg",
    heading: "Aluminium Louver",
    paragraph:
      "Aspect Aluminium providing aluminium louvres in Dubai and other emirates of UAE. Aluminium Louvres have been an important part of the architecture. People used aluminium louvres systems in the construction of their homes, mostly to protect themselves from the sun. ",

    link: "/products/aluminium/aluminium_louver",
    material: "aluminium",
  },
  {
    id: 14,
    img: "/img/aluminium-mashrabiya.jpeg",
    heading: "Aluminium Mashrabiya",
    paragraph:
      "Mashrabiya has close connections with the harsh desert climate, and that’s the reason why mashrabiya is utilized the most. Arabic countries have intense heat and severe aridity. This problem demands a more smart solution to sustain life and existence. ",

    link: "/products/aluminium/aluminium_mashrabiya",
    material: "aluminium",
  },

  {
    id: 15,
    img: "/img/projects/upvc/upvc-slide-fold.jpg",
    heading: "UPVC Doors",
    paragraph:
      "Because of its sturdiness and longevity, UPVC sliding doors are incredibly durable and require little maintenance. UPVC Slide and Fold Doors are used mainly in large open areas to appear like a virtual wall. The door is a collection of multiple frames which has fixed or moving frames.",

    link: "/products/upvc/upvc_doors",
    material: "upvc",
    type: "doors",
  },
  // {
  //   id: 16,
  //   img: "/img/projects/upvc/upvc-sliding-doors.jpg",
  //   heading: "UPVC Sliding Doors",
  //   paragraph:
  //     "Because of its sturdiness and longevity, UPVC sliding doors are incredibly durable and require little maintenance",

  //   link: "/products/upvc/upvc_doors",
  //   material: "upvc",
  //   type: "doors",
  // },
  {
    id: 17,
    img: "/img/projects/upvc/upvc-sliding-windows.jpg",
    heading: "UPVC Sliding Windows",
    paragraph:
      "An UPVC Sliding window comprise of frames that can slide back and forth. The technical term for frames here is sash. With great ventilation and aesthetics, uPVC sliding windows offer unmatchable high-quality uPVC frames coupled with Glass of choice",

    link: "/products/upvc/upvc_sliding_windows",
    material: "upvc",
    type: "windows",
  },
  {
    id: 18,
    img: "/img/projects/upvc/upvc-tilt-and-turn.jpg",
    heading: "UPVC Tilt & Turn Windows",
    paragraph:
      "UPVC Tilt and Turn Windows are a great choice when it comes to choosing a window as it has a perfect blend of intelligent operability and smart asthetics.",

    link: "/products/upvc/upvc_tilt_and_turn_windows",
    material: "upvc",
    type: "windows",
  },
  {
    id: 19,
    img: "/img/projects/upvc/upvc-fixed-window.jpg",
    heading: "UPVC Fixed Windows",
    paragraph:
      "The purpose of UPVC Fixed Windows is to provide architectural completion and aesthetical framing of the house. As the name suggests, they are fixed and don’t have any moving or operable parts. Usually these windows are installed at a height where ample amount of sunlight can enter the room",
    link: "/products/upvc/upvc_fixed_windows",
    material: "upvc",
    type: "windows",
  },
  {
    id: 20,
    img: "/img/projects/glass-garden-rooms/garden-rooms1.jpg",
    heading: "Garden Rooms",
    paragraph:
      "Our Contemporary Garden Room Collection offers clean lines and large full drop glass in the windows and doors. Generally in a pent roof to keep under the planning rule, our garden rooms are also available in an apex roof.",
    link: "/garden-rooms",
    material: "glass",
  },
  {
    id: 21,
    img: "/img/projects/glass-garden-rooms/glass-rooms1.jpg",
    heading: "Glass Rooms",
    paragraph:
      "We have a wide range of glass rooms, constructed to suit your lifestyle. Our glass rooms are made of high quality selected materials that have the added benefit of low maintenance.",
    link: "/glass-rooms",
    material: "glass",
  },
];

export const ProductData = {
  aluminium_doors: [
    {
      id: 1,
      imagetype: "door",
      img: "/img/projects/doors/aluminum/aluminum-doors1.jpg",
      heading: "High-Quality Aluminium, Pivot, & Revolving Doors",
      paragraph:
        "Interior Doors are the most important element in the interior decoration of space which must be made ideal to give a profound effect, aesthetic statement, and volatizing description to your space. A door is chosen according to your space and architecture as there are many models of doors available. We at Dubai at Aspect serves you with the best doors to enhance the beauty of your space and give it a solid look.",
    },
    {
      id: 2,
      img: "/img/projects/doors/aluminum/aluminum-doors.jpg",
      heading: "Aluminium Doors",
      paragraph:
        "The quality of aluminium cannot be stressed enough, it is the best material. Aluminium doors are that specific type of doors that can adapt themselves to any space and its style. Aluminium is a very trending material that is used for both Aluminium windows and doors. Aluminium doors also serve as a perfect match with Aluminium Windows. Aluminium doors are suitable both for homes and industrial usage. Aspect provide Aluminium powder coated doors with various colours and designing options according to our client’s space that blends in with their space’s interior and requires very little maintenance. Height, width, and other measurements of the door are kept according to the ceiling.",
    },
    {
      id: 3,
      img: "/img/projects/doors/aluminum/pivot-doors.jpg",
      heading: "Pivot Doors",
      paragraph:
        "Now avail sleek pivot doors at Aspect with a unique vertical pivot opening with a smooth operation of panels to give your space a unique, exceptional look and give an overall quality to your home. The pivot door system is excellent and useful for doors that contain large glass. Glass and wood pivot doors give a look of sheer modernity, warmth, and elegance that no other door can provide nowadays. Our team makes sure to manufacture strong pivot doors, heat and sound insulated.",
    },
    {
      id: 4,
      img: "/img/projects/doors/aluminum/revolving_doors.jpg",
      heading: "Revolving Doors",
      paragraph:
        "Revolving doors are one of the most professional doors that are used as the first visual focus while entering buildings, hotels, offices, etc. That give a fancy yet professional touch to that specific space. Revolving doors serve as an attractive feature. Aspect not only provides doors for homes but for industrial purposes as well according to their priority and professional style. As far as the material is concerned our team makes sure to manufacture premium quality revolving doors as they are used for professional spaces, we make sure to make them long-lasting.",
    },
  ],

  aluminium_windows: [
    {
      id: 1,
      imagetype: "window",
      img: "/img/projects/windows/aluminum/aluminum-windows1.jpg",
      heading: "Best Aluminium Windows",
      paragraph:
        "Windows are vital for the functionality of our spaces as they help up with the ventilation hence provide a view too. They provide us with an aesthetic view and brightening light. The aluminium interior has replaced all other interiors in the market as it has been a game-changer and has set a remark in the interior industry. Aluminium is the best and the right choice for anyone who’s thinking of updating their interior. There are many types of windows available at Aspect with new window designs and styles to elevate, boost, and strengthen your space’s look. Our clients matter to us the most and are our top priority, and their designing priority is our priority.",
    },
    {
      id: 2,
      imagetype: "window",
      img: "/img/projects/windows/aluminum/aluminum-windows2.jpg",
      heading: "Aluminium Sliding Window",
      paragraph:
        "Windows are vital for the functionality of our spaces as they help up with the ventilation hence provide a view too. They provide us with an aesthetic view and brightening light. The aluminium interior has replaced all other interiors in the market as it has been a game-changer and has set a remark in the interior industry. Aluminium is the best and the right choice for anyone who’s thinking of updating their interior. There are many types of windows available at Aspect with new window designs and styles to elevate, boost, and strengthen your space’s look. Our clients matter to us the most and are our top priority, and their designing priority is our priority. We try our best to change their vision into reality through our creativity and stunning designs with minimal yet deep details.",
    },
    {
      id: 3,
      img: "/img/projects/windows/aluminum/aluminum-windows3.jpg",
      heading: "Double Glazed Windows",
      paragraph:
        "Double glazed windows are also known as insulated glass units. If you are looking for double-glazed windows, you are at the right place. Our double-glazed windows are created keeping in mind the insulation. Aspect offers the best double-glazed windows that act more relaxed in summer, warmer in winters, and prevent unwanted noise for a calmer environment as compared to single glazed windows. There are many advantages and benefits that double glazed windows can provide compared to single glazed windows, as stated above. We offer different sorts of pastel colours and frames, be it PVC, wood, or timber of high verified quality, depending on the client’s choice. Double glazed windows are worth buying.",
    },
    {
      id: 4,
      imagetype: "window",
      img: "/img/projects/windows/aluminum/aluminum-windows4.jpg",
      heading: "Bay and Casement Window",
      paragraph:
        "Bay and Casement windows at Aspect are made by combining three or more windows of exceptional designs as per clients’ choice and will. They are saving energy; our elegant casement windows are attractive. All our casement windows have an excellent energy performance rating and fall under industry security standards. We make sure to keep all the factors in our mind while designing for our clients. There are further many types of Bay and casement windows, such as the canted bay windows or the boxy bay windows, and the list goes on. The modern bay and Casement windows have small frames with large expanses of glass.",
    },
  ],

  aluminium_pergola: [
    {
      id: 1,
      imagetype: "pergola",
      img: "/img/projects/pergolas/pergola1.jpg",
      heading: "Best Aluminium Pergola",
      paragraph:
        "Are you looking for the best Aluminium Pergola to extend your living space? Aluminium Pergola manufacturers in Dubai at Aspect is the best place to get Pergola for your space and give your space a volume and sense of style to make your day and environment better If you are someone who loves spending time outdoors, our Pergola is the best option for you and worth buying because it will be the best statement for your space available in every colour. Pergola serves several various services.",
    },
    {
      id: 2,
      imagetype: "pergola",
      img: "/img/projects/pergolas/pergola2.jpg",
      heading: "Gazebo Builder",
      paragraph:
        "Aspect builds the best Gazebo for one’s property, which provides a decent yet richness in the look. Our Gazebos are constructed in many materials such as wood, which provides a warm atmosphere to the whole surrounding and the wood can be painted as well as per our client’s choice and preference. Our Gazebos are strength-wise constructed by using sheer quality material in a way that they can last through any worse weather condition. Our team makes sure to provide our client a customized plan for Gazebo builder including the style, colour, and dimension.",
    },
    {
      id: 3,
      imagetype: "pergola",
      img: "/img/projects/pergolas/pergola3.jpg",
      heading: "Modern Aluminium Pergola",
      paragraph:
        "Aluminium Pergola is a very trending feature nowadays as the world is changing. If you are looking for a modern Pergola, Aluminium is the best option for it. Aluminium itself has many flawless qualities that cannot be found in any other material. Aluminium Pergola lasts longer than any other material available. Modern Aluminium Pergola serves the best looks wherever it is placed, besides looks its quality is unmatchable. Our Pergola is multifunctional, it protects you from both the sun and heavy rain.",
    },
    {
      id: 4,
      imagetype: "pergola",
      img: "/img/projects/pergolas/pergola4.jpg",
      heading: "Aluminium Roof Pergola",
      paragraph:
        "Our Aluminium Pergola is also available with an adjustable louvered roof in various designs. Our Aluminium Roof Pergola offers functionality, durability, and a visually aesthetic appearance. The best thing about our Roof Pergola is that their louvered roof can be closed as well. Yes, our Aluminium Roof Pergola has multiple functions such as most importantly it is rainproof and maintenance-free. Aluminium roof pergola is available both for residential and commercial usage. In commercial use, Aluminium Roof Pergola offers great versatility and looks which attracts the customers.",
    },
    {
      id: 5,
      imagetype: "pergola",
      img: "/img/projects/pergolas/pergola5.jpg",
      heading: "Outdoor Pergola",
      paragraph:
        "If you are looking for an outdoor Pergola, Aspect is your best choice to select the right model of outdoor Pergola. Our outdoor Pergola gives you the chance to enjoy the weather without being worried. Our Outdoor Pergola not only can support plants on its rooftop but makes it easy to hang other flowers and lanterns according to your own choice to give it a smooth look and touch in your outer space",
    },
    {
      id: 6,
      imagetype: "pergola",
      img: "/img/projects/pergolas/pergola6.jpg",
      heading: "Glass Pergola",
      paragraph:
        "Glass Pergola can never be outdated as they serve fancier looks than the Pergola even though they are on the expensive side, but Glass Pergola is one of the prettiest structures you will have ever seen which is manufactured by the usage of stronger material. Our Glass Pergolas pillars are available as well in many colours’ pallets of different shades and designs that will make you book yourself a Glass Pergola at Aspect.",
    },
  ],

  bifolding_doors: [
    {
      id: 1,
      img: "/img/projects/bi-fold/bifold-doors.jpg",
      heading: "Aluminium Bi-Fold Doors",
      paragraph:
        "Bifold Doors that are made of Aluminium are the most strong, functional, and secure doors. Bi-fold engineered doors at Aspect are available in 2 to 8 folds, even more than that in different designs depending on the customer’s choice. Our designers at Aspect create the perfect harmony, great sense of designs and provide balance according to the space that is compatible with all sorts of the interior. Aluminium Bi-Fold Doors are the type of doors that are never out of style and are always serving a minimal, decent and elegant look with a smooth movement inwards or outwards because of our Aluminium Bi-Folding doors are manufactured by blending high-quality products according to its requirements. We make sure not to compromise on the quality and use any cheap product. Our powder-coated Aluminium Bi-Folding Doors are the safest option as they are not fragile or easily breakable as they are made up of stiff quality. We provide Bifold closet doors, automatic bifold garage doors, folding panel doors, frameless bifold external doors",
    },
    {
      id: 2,
      img: "/img/projects/bi-fold/sliding_pocket_doors.jpg",
      heading: "Sliding Pocket Door",
      paragraph:
        "Pocket doors are a fancy and modern style of door with a sliding option. At Aspect you can find many Aluminium Sliding doors in different styles and designs such as double pocket doors or wood pocket doors with attractive aluminium door handle. Just like our other products, sliding pocket doors can be custom-built as well according to the client’s preference. Different colours and options are in sliding pocket doors. The most useful feature of sliding pocket doors is that they save space and can be used in a tight location in any part of the house where other options are not suitable. On the other hand, it can be used in large spaces as well to divide the room in a fancy way that creates a more welcoming environment, a sliding pocket door simply blends in the interior no matter where they are being used.",
    },
    {
      id: 3,
      img: "/img/projects/bi-fold/rollup_garage_door.jpg",
      heading: "Roll Up Garage Door",
      paragraph:
        "Various garages use Roll-up garage doors as a high quality solution and often chosen when there is a less garage space. These doors are ideal solution when there is a problem finding more garage space - thanks to the use of vertical tracks and no cieling guides, no additional space is needed to mount the door. At Aspect Aluminium we provide our clients various color options allowing them to match the color of the roll-up garage doors with their other joinery elements. We provide high quality workmanship, armour and case made of strong and durable aluminium. These are aesthetic box into which the door blade is rolled up. Due to the space-saving design, roller garage doors are suitable for opening with engineering utilities at the top (water pipe, wiring etc). Roller garage doors can be installed on the opening or in the opening from outside or inside. ",
    },
  ],
  cast_aluminium_doors: [
    {
      id: 1,
      img: "/img/best-cast-aluminium-gates.jpg",
      heading: "Best Cast Aluminium Gates Manufacturer",
      imagetype: "others",
      paragraph:
        "We take great pleasure in introducing our all products as we are confident about the quality we supply to our clients. We are this successful because of the high-quality products we provide under an affordable budget. We don’t only provide new doors, but we can redesign your previous doors as well according to your wishes. An Aluminium door is the best choice in terms of quality, maintenance as it requires very little maintenance and lasts for a longer period maintaining its structure and style. Our doors are fully furnished, powder-coated, and fabricated by the usage of the highest quality material. All doors are measured according to the customer’s space and dimension.",
    },
    {
      id: 2,
      img: "/img/cast-aluminium-gates.jpg",
      heading: "Cast Aluminium Gates",
      paragraph:
        "The best gate that you can avail of for your space is our Cast Aluminium Gate with qualities that you will never be able to find somewhere else. Our Cast Aluminium Gate will enhance the architecture of your space with its decent style and colours. We offer the best colour combinations. Our cast Aluminium is the best material that is resistant to weather which doesn’t change its structure but remains in the same rigid structure no matter how worse the weather condition gets. No matter what style you are looking for be it classical, modern, fancy or any other Aspect has you all covered. We can design any type of Gate our customers want according to their customized plan. We will keep their preference our priority. Our designers listen to the client’s wishes and create the best and perfect gate for entrance.",
    },
    {
      id: 3,
      img: "/img/aluminium-entry-door.jpg",
      heading: "Aluminium Entry Door & Gate",
      paragraph:
        "An aluminium entry door and gate have numerous advantages as compared to any other material. For example, the structure ability, strength, thermal insulation, weather resistance, acoustic insulation, and many other qualities. Combining all these flawless and premium qualities makes the Aluminium Entry Door and Gate worth buying. The biggest quality is that at Aspect the workers put all their hard work in combining all these qualities and manufacturing the best aesthetically pleasing Aluminium Entry Door and Gate. The entrance door is the main part of a house or any property which catches the first impression of an individual and our company makes sure to provide our clients with a door design that will change the look of your space.",
    },
    {
      id: 4,
      img: "/img/aluminium-louver-door.jpg",
      heading: "Aluminium Louver Door",
      paragraph:
        "In our various options for Aluminium gates and doors, Aluminium Louver Door is available as well at Aspect and Glass Companies. Many people are very about the security issue when installing the Louver door, safe to say that you don’t have to worry about the security. Our Aluminium Louver door is manufactured keeping the security factor a priority. The modern equipment and technology that we use in manufacturing our Aluminium Louver Door ensure safety. Besides this, we also offer several various accessories with our Aluminium Louver Door. Our Aluminium Louver doors provide free passage that reflects light and is easy to clean as well. Aluminium Louver doors are the best source for ventilation in the space as they control the airflow through their space.",
    },
    {
      id: 5,
      img: "/img/modern-front-door.jpg",
      heading: "Modern Front Door in Dubai",
      paragraph:
        "Our Modern Front Door variety in Dubai is one of our best collections in which we offer many modern Doors that are automatic as well. An infinite selection of colour shades is provided as well so that clients can choose the shade according to their architecture. Mate or glossy, structural, or metallic colour tones all are available under Aspect. Our Modern Front Doors are manufactured using thick insulating leaves, three to four layers of glazing, and triple sealing to make it highly thermal insulated. In this way, our modern front door serves multiple functions, prevents heat loss, and reduces energy costs.",
    },
  ],
  aluminium_staircase: [
    {
      id: 1,
      img: "/img/spiral-staircase.jpg",
      heading: "Spiral and Round Staircase",
      paragraph:
        "Staircases nowadays have become an important part of the architecture world. Stairs are the focal point of a space due to which it is very necessary to install yourself a great design of stairs that are durable and minimal. Aspect all top quality highly finished and welded stairs meet all the standards of clients. Glass, wood, aluminium, metal, and stainless steel everything you need is available here.",
    },
    {
      id: 2,
      img: "/img/modern-staircase.jpg",
      heading: "Modern Staircases",
      imagetype: "others",
      paragraph:
        "Aspect offers a Modern staircase in Dubai with various designs and installations. A huge collection of modern staircases in wood, steel, aluminium, glass, and many other materials are available for clients to choose from. We offer our customers custom modern staircases in Dubai that are manufactured, engineered, and designed to fulfil the specific requirements of our clients. Incredible strong strength, durable, long-lasting value, and flawless finishing all qualities are available in our Modern Staircase in Dubai. From spiral staircases to steel staircases, glass staircases, and modern stairs railing designs in steel, we offer a wide range of options to suit your style and space. Our expertise extends to small space stairs, round staircases, and modern steel stairs that effortlessly blend functionality and aesthetics. We also provide aluminium stairs and aluminium staircase railings, combining strength and lightweight construction. Trust Aspect Aluminium to bring elegance and innovation to your staircase with our exceptional designs and high-quality materials.",
    },
    {
      id: 3,
      img: "/img/aluminiumStaircase.jpg",
      heading: "Spiral Staircase",
      paragraph:
        "Wood, steel, metal, concrete, and glass all options are available for Spiral Staircase Dubai with their edges designed. Working on a Spiral staircase is a huge project because the manufacturing of a spiral staircase with custom measurements is huge complexity and further takes a lot of time and effort to design it flawlessly according to the space’s measurements, height, and diameter, etc. Diameter is the most option as it determines if the staircase even fits in the space aesthetically or not.",
    },
    {
      id: 4,
      img: "/img/round-staircase.jpg",
      heading: "Round StairCases",
      paragraph:
        "We put our efforts to enhance the details of Round Staircase in Dubai which matches the whole interior of our client’s space. We combine various materials such as glass and steel to accomplish an aesthetic architectural round stairway. The major concern of our company is to build safety filled around staircases. Just select the design, colour, and material and leave the rest to our engineers. They will bring out the best according to your needs, selection, and priorities. Most people assume that round staircases have been nowadays outdated but no they haven’t, they are still highly used all over.",
    },
    {
      id: 5,
      img: "/img/railing-stairs.jpg",
      heading: "Aluminium Railing & Stairs",
      paragraph:
        "Aluminium is by far the best option for railing and stairs. Aluminium at Aspect is the best Aluminium work company material available that is durable, water-resistant, heat resistant and has many other countless qualities with smooth options of furnishing is available. Moreover, our Aluminium Railing and Stairs are also further powder coated to give a neat and representable look. Aluminium railing from Aspect is made up of a blend of by blend of unique materials. Our aluminium stairs railing, and grill designs provide a sleek finish and touch to stairs or any product that it is used in.",
    },
  ],
  aluminium_composite_cladding: [
    {
      id: 1,
      img: "/img/aluminium-composite-cladding.jpeg",
      heading: "Aluminium composite Cladding",
      paragraph:
        "Aluminium composite cladding system or ACP is typically a metal curtain wall system. ACP is made from lightweight aluminium with hard insulation. Aluminium composite cladding is used for exterior cladding, column covers, fascia’s and canopies, interior wall and partition panels, parapets, louvers and sunshades. We have extensive experience and skill set in providing ACP fabrication drawings and ACP installation drawings for offices,  shopping malls, apartments and hospitals. Our expertise in ACP detailing also include 2D & 3D detailing & drafting. We have a highly successful track record and a growing but repetitive clientele which prove the value of our services. We meet the client’s demands and expectations with focus on quality and reliability.",
    },
    {
      id: 2,
      img: "/img/aluminium-composite-cladding2.jpeg",
      heading: "Aluminium cladding UAE",
      paragraph:
        "Enhance the exterior of your building with our high-quality aluminium cladding solutions. Our aluminium cladding sheets offer durability and aesthetic appeal, while composite cladding exterior options provide a modern and sleek look. With aluminium cladding rails, we ensure secure installation and longevity. Choose from our range of colours, including grey aluminium cladding, to achieve the desired aesthetic. For interior spaces, our interior aluminium cladding adds a touch of elegance and sophistication. Our skilled team excels in aluminium cladding work, delivering impeccable results. Contact us today to discuss your project requirements and receive a competitive quote on aluminium cladding cost per m2. We proudly serve the UAE, providing top-notch aluminium cladding solutions that meet your needs and exceed your expectations.",
    },
  ],
  aluminium_louver: [
    {
      id: 1,
      img: "/img/aluminium-louver.jpeg",
      heading: "Aluminium Louver",
      paragraph:
        "Aspect Aluminium providing aluminium louvres in Dubai and other emirates of UAE. Aluminium Louvres have been an important part of the architecture. People used aluminium louvres systems in the construction of their homes, mostly to protect themselves from the sun. Aluminium louvres are shutters that have horizontal slats that can be put into different angles to shade from the sun. In the present day we mainly place aluminium louvers for decorative purposes and architectural beauty. Louvres were often made, and sometimes are still made from wood. At Aspect Aluminium, we take pride in being leading manufacturers of high-quality aluminium louvers and louvre doors. Our innovative designs offer excellent ventilation solutions while adding a touch of modernity to any space. Whether you need exterior aluminium louvers for building facades or an aluminium louvered pergola to create a stylish outdoor retreat, our products are crafted with precision and durability in mind. ",
    },
    {
      id: 2,
      img: "/img/aluminium-louver2.jpeg",
      heading: "Modern Aluminium Louver",
      paragraph:
        "Modern aluminium louvres systems are built with metals like stainless steel, titanium, and aluminium. Occasionally louvres are also made of glass or copper. Louvre systems are also used in front doors, closets and ceilings. The use of louvres inside the home is often shading, ventilation, or just decoration. Aluminium louvres can be built to help sun control or temperature control for buildings. Many architects also choose louvres to decorate their design. With a wide range of options and customizable features, our aluminium louvers provide both functionality and aesthetic appeal. Trust our expertise and choose our aluminium ventilation louvers to enhance your architectural projects with sleek and efficient designs.",
    },
  ],
  aluminium_mashrabiya: [
    {
      id: 1,
      img: "/img/aluminium-mashrabiya.jpeg",
      heading: "Aluminium Mashrabiya",
      imagetype: "others",
      paragraph:
        " Experience the timeless allure of Mashrabiya, a traditional architectural feature that has graced the landscapes of the Middle East for centuries. In the UAE, discover the rich heritage and contemporary interpretations of Mashrabiya, a testament to the region's architectural legacy. Experience the modern evolution of this timeless art form with aluminium Mashrabiya screens, combining traditional elegance with durability and versatility. Immerse yourself in the captivating allure of Mashrabiya and witness how it weaves together heritage, design, and innovation. Immerse yourself in the intricate and captivating world of Mashrabiya design, where meticulous craftsmanship intertwines with artistic expression. Explore the delicate beauty of Mashrabiya patterns, intricately carved or crafted to create mesmerizing light and shadow play.",
    },
    {
      id: 2,
      img: "/img/aluminium-mashrabiya2.jpg",
      heading: "Mashrabiya lounge with ventilation solution",
      imagetype: "others",
      paragraph:
        "Unwind in the serene ambiance of a Mashrabiya lounge, where the delicate latticework creates an enchanting atmosphere of privacy and tranquility. Mashrabiya has close connections with the harsh desert climate, and that’s the reason why mashrabiya is utilized the most. Arabic countries have intense heat and severe aridity. This problem demands a more smart solution to sustain life and existence. People tired of the heat from the desert and brought many solutions to resist the heat. One method was that heavy walls were used because people thought that in this way, the heat would lessen. Even though these walls were effective in reducing the heat since the walls absorbed cold temperature at night. But the problem with this technique is that the walls had tiny ventilation holes which resulted in dimly lit home. With mashrabiya, the problem was solved to a great extent by letting in enough light and prevented heat seeping in through the wall opening. With more of aesthetic charm, mashrabiyas provide sufficient gust of air into the room.",
    },
  ],

  glass_partition: [
    {
      id: 1,
      img: "/img/projects/partitions/partition2.jpg",
      heading: "Glass Partition In Dubai",
      paragraph:
        "Glass partition which is the feature of interior design creates an aesthetically attractive pleasing environment which gives a tasteful impression be it at an office or even at home. Glass partition allow much lighter to pass through – making for a brighter, airier environment. Natural lightening system has been provided for better vision. Manual and automatic options for revolving doors are also available at Aspect Aluminium and Glass companies. We offer space saving solution and advanced ambience all over Dubai and all over UAE.",
    },
    {
      id: 2,
      img: "/img/projects/partitions/partition3.jpg",
      heading: "Aluminium Partition",
      paragraph:
        "Aluminium partitions prove to be one of the most durable partitioning systems available, as they require less maintenance hence this is exactly why people prefer more Aluminium glass and we are the leading glass partition suppliers. If you’re looking for aluminium glass partition, we offer huge diversity of sleek aluminium glass manufactured of the best high quality and design that meets our customer priority. Aluminium is more resistant to rust and corrosion than any other metal.",
    },
    {
      id: 3,
      img: "/img/projects/partitions/partition4.jpg",
      heading: "Office Partition",
      paragraph:
        "Our partition wall design not only serves as an eye-catching interior but guarantees the conversation remain private in the space as compared to others and suitable for all types of offices. In addition to privacy, it helps to diminish noise to a great extent which helps to work more productively. Talking about winters, our Aluminium windows have a polyamide thermal break so that the cold stays out and the warm stays in. As compared to other partition options, office room glass partition wall doesn’t take up much space instead they are incapable of blending like its glass or cement counterpart.",
    },
    {
      id: 4,
      img: "/img/projects/partitions/partition1.jpg",
      heading: "Frameless Glass Wall Partition",
      paragraph:
        "Aspect comes with all space planning in the hospitality industry, the frameless glass wall systems can optimize all area within very decent architectural design. Glass partitioning systems are available at Aspect with frameless glass as well for full height glazing for a smooth and sleek finish available in different colours and variety of designs that are fixed with the help of our professional team according to your choice, Constructed from high-quality materials – tempered or laminated glass.",
    },
    {
      id: 5,
      img: "/img/projects/partitions/partition5.jpg",
      heading: "Glass Work",
      paragraph:
        "We at Dubai at Aspect offer you the best refined glass that is manufactured perfectly without any compromise on its quality as glass is being used widely nowadays be it doors, partitions, cupboards because it enhances the beauty of a certain place with its transparent characteristics. We supply a wide range of glass at reasonable prices. Our professional staff makes sure to install the glass carefully and flawlessly.",
    },
    {
      id: 6,
      img: "/img/projects/partitions/partition6.jpg",
      heading: "Frameless Sliding Glass Door",
      paragraph:
        "Sliding glass doors at Aspect serves as an appearance of beauty with minimal framing of aluminium as we have expertise in them. With up to the mark sliding doors, you can expect stainless steel components and hardware, laminated safety glass, smooth and polished running sliding panels and a secure locking mechanism.",
    },
  ],

  shower_glass: [
    {
      id: 1,
      img: "/img/shower-glass.jpg",
      heading: "Shower Glass Partition",
      imagetype: "others",
      paragraph:
        "Upgrade your bathroom with stunning shower glass solutions. From luxurious shower glass partitions and elegant shower doors to sleek glass enclosures, we offer a wide range of options to elevate your bathing experience. Enhance privacy and style with a custom-designed shower glass partition, creating a distinct separation in your bathroom while allowing natural light to flow. Transform your shower area with high-quality glass shower doors, providing a seamless entry and adding a touch of sophistication to your space. For a sleek and modern look, consider a sleek shower partition or a contemporary bathroom glass door. Different accessories to fit Shower Glass can also be provided as per client’s choice.",
    },
    {
      id: 2,
      img: "/img/shower--glass-partition.jpg",
      imagetype: "others",
      heading: "Shower Glass Partition & Enclosure",
      paragraph:
        "Explore our selection of shower boxes and enclosures, offering a spa-like experience in the comfort of your home. Create a seamless and spacious feel with a glass bathroom design that showcases elegance and functionality. Opt for a bath glass partition to define your bathing space, combining practicality and aesthetics. Additionally, our range of bathroom partitions ensures privacy and organization without compromising style. If you're in Abu Dhabi, discover our exceptional shower glass partition options tailored to your preferences. Enjoy affordable luxury with our selection of cheap glass shower enclosures, providing both quality and value. Upgrade your bathroom partitions with wall-mounted solutions that optimize space and enhance the overall aesthetic appeal of your bathroom.",
    },
    {
      id: 3,
      img: "/img/besides-shower-glass.jpg",
      heading: "Besides Bathroom Glass Partition",
      paragraph:
        "Now enhance your bathroom’s look with our bathroom shower glass in Dubai. Our Glass shower doors are the best product that you can avail for your bathroom space which adds beauty to your bathroom. We offer a variety of furnishes and finishes for shower glass which can change the whole look of your bathroom.",
    },
    {
      id: 4,
      img: "/img/frameless-shower.jpg",
      heading: "Frameless Bathroom Shower Enclosures",
      paragraph:
        "Frameless Bathroom Shower enclosures are a little on the expensive side but if you want to give your bathroom a fancy yet decent and modern look, it will be worth the money spent on it. Our experts at Aspect and Glass Companies help you make the best design for Frameless Bathroom Shower Enclosures. Our frameless bathroom shower enclosures will transform the look of your bathroom. Our frameless Bathroom shower enclosures are versatile and serve various benefits. You can select any frameless bathroom shower enclosure from a broad variety of our designs. Our Frameless Bathroom Shower Enclosures serve the best styles and configurations. Metal hinges or handles can also be added as per your choice.",
    },
  ],

  sliding_glass_doors: [
    {
      id: 1,
      img: "/img/projects/doors/glass/glass-doors1.jpg",
      heading: "Best Sliding Glass Doors in Dubai",
      imagetype: "door",
      paragraph:
        "Aspect Aluminium in Dubai is leading aluminium doors and window manufacturer in UAE and we provide best glass windows and glass doors with different services such as sliding Glass Door replacement, sliding folding door, aluminium glass door,  sliding patio door, full wall sliding glass doors, frameless glass doors, automatic sliding door and many others. We have the best Sliding Glass Doors options for our clients either commercial use or residential use and we provide best aluminium doors in Dubai. Glass is a material that can only be handled by professionals who are experts in dealing with glass and Aspect team are one of those people who know how to carry out their work professionally. Additionally, we offer top-notch sliding glass door locks to ensure the security and peace of mind you deserve.",
    },
    {
      id: 2,
      img: "/img/projects/doors/glass/glass-doors2.jpg",
      imagetype: "door",
      heading: "Frameless Sliding Glass Door",
      paragraph:
        "Frameless Sliding Glass at Aspect ALuminium is one of the finest form of work you will see in Dubai. From sleek framed shower glass to getting panoramic view over the garden frameless doors makes your space luxurious. Our frameless sliding doors are made up of very stiff glass which cannot break as compared to other low-quality glass. Standards at Dubai at Aspect ensure that glass is of high quality and perfectly installed keeping in view the safety parameters.",
    },
    {
      id: 3,
      img: "/img/projects/doors/glass/glass-doors3.jpg",
      imagetype: "door",
      heading: "Glass Door Dubai",
      paragraph:
        "If you are someone who is a fan of Glass Doors, you are welcomed at Aspect who serves the best quality glass door in Dubai and all over UAE. Our Glass Doors are designed to add elegance to your space. Various types of Glass Doors are available at our company, with various designs and colors. Our glass is highly furnished to give a complete and refined look. Our Glass Doors make the space ten times attractive once they are installed.",
    },
    {
      id: 4,
      img: "/img/projects/doors/glass/glass-doors4.jpg",
      imagetype: "door",
      heading: "Revolving Glass Doors Dubai",
      paragraph:
        "  We specialize in providing premium quality revolving glass doors that combine functionality, elegance, and cutting-edge design. Our expert team is dedicated to delivering innovative solutions that transform your entrances into architectural masterpieces. With our extensive range of revolving glass doors, we offer you the opportunity to create a grand and impressive entrance that leaves a lasting impression. Whether you require revolving doors for commercial buildings, retail spaces, or high-traffic areas, we have the expertise and the products to meet your unique requirements. Trust Aspect Aluminium to enhance your space with our exceptional revolving glass doors, designed to elevate the aesthetics and functionality of your property. Moreover, our Revolving Glass Doors are highly thermal insulated, resisting unwanted noise and worst weather conditions. These qualities are exactly what differentiates Dubai at Aspect from all other companies.",
    },
  ],

  curtain_walls: [
    {
      id: 1,
      img: "/img/curtain-walls.jpg",
      heading: "Curtain Walls",
      paragraph:
        "Aspect Aluminium takes immense pride in its expertise and craftsmanship when it comes to curtain wall solutions. Our cutting-edge technology and meticulous attention to detail enable us to create stunning and functional curtain wall designs that leave a lasting impression. With our expertise in curtain wall windows, we provide seamless integration of glass elements, allowing for maximum natural light and breathtaking views. Our meticulous approach extends to every aspect, as we focus on curtain wall details that enhance both aesthetics and performance. Utilizing high-quality curtain wall materials, including durable aluminum, we offer innovative solutions that withstand the test of time. Our aluminum curtain wall system incorporates a thermal break design, ensuring optimal energy efficiency and climate control.",
    },
    {
      id: 2,
      img: "/img/curtain-wall-dubai.jpg",
      heading: "Curtain Wall Dubai",
      paragraph:
        "Aspect offers the best Curtain Wall in Dubai. At Aspect Aluminium, we understand the importance of versatility, and thus, we offer a range of types of curtain wall systems to meet diverse architectural requirements. From single glazed curtain wall designs to advanced multi-pane configurations, we tailor our solutions to harmonize with any architectural vision. We offer the best in-room separator and partition curtain as well that are functional and aesthetic.",
    },
    {
      id: 3,
      img: "/img/curtain-wall-system.jpg",
      heading: "Aluminium Curtain Wall System",
      paragraph:
        "Aluminium serves as a plus point if you are looking for aluminium Curtain Wall system which enhances the space by serving its function. A variety of shapes, textures, colors, and finishes are available at Aspect and Glass Companies for aluminium Curtain Wall System. Glass and metal panels can also be insulated within the curtain wall as per the client’s choice. Double glazed glass with aluminium framed Curtain Walls is also available. Our aluminium Curtain Walls are manufactured from a mixture of the best materials that are highly thermal and can cope with any weather condition such as extremely windy weather, snow, or even earthquakes.",
    },
    {
      id: 4,
      img: "/img/white-wall.jpg",
      heading: "Curtains For White Walls",
      paragraph:
        "For curtains with white walls, Aspect has a huge collection through which you can select the color and design option. If you are confused about which curtain to choose for white walls, our team of experts at Aspect will give you the best advice and select the best curtains for white walls. To achieve the perfect styling of Curtains for White and grey walls, our engineers provide plans in a way that can enhance your space and make it look elegant according to your space interior and color combination without being too overwhelming.",
    },
  ],

  glass_railing: [
    {
      id: 1,
      img: "/img/projects/railing/glass-railing.jpg",
      heading: "Glass Railing",
      imagetype: "others",
      paragraph:
        "Draw out the view of your home with our unbeatable quality of glass railings which helps to enhance your living space. Glass railings aren’t something that everyone can handle as they are fragile, our glass are manufactured stronger than the usual ones that requires very low maintenance only if required. If you are someone who is looking to give your space a modern, decent and minimal look and touch glass railing is your best choice.",
    },
    {
      id: 2,
      img: "/img/projects/railing/glassRailing.jpg",
      heading: "Glass Handrail",
      paragraph:
        "High quality glass handrails are now available at Aspect with a wide range of patterns and designs that are not fragile but are thick, stiff and rigid manufactured to keep you safe. Our glass handrails are manufactured, powder coated and brushed into fine products with flawless finishing of wood or stainless steel available in different shapes and sizes which compliments the other interior present in your space too. Our designs are made by keeping the trend and latest fashion in our mind.",
    },
    {
      id: 3,
      img: "/img/projects/railing/stairs.jpg",
      heading: "Stairway Glass",
      paragraph:
        "Remodel your stairways look with our stairway glass that does not corrodes at the most rational and reasonable prices. You will be astonished at how many options do we have of Stairway glass at Aspect of premium and well refined quality that radiates modern and luxury vibes. The stairway glass in todays era has become a classic yet modern style to enhance your space. We make sure not to only manufacture a well refined stairway glass but also to install it exquisitely into your space.",
    },
    {
      id: 4,
      img: "/img/projects/railing/guard-railing.jpg",
      heading: "Guard Railing",
      paragraph:
        "Guard railings that are used as a boundary feature to keep people off from a limited and restricted area are now also conveniently available at Aspect manufactured and crafted from the best steel available that are easily installed and can be relocated as well without any hustle. Its accessories are also available such as its corner cap and mounting bracket.",
    },
    {
      id: 5,
      img: "/img/projects/railing/glass-stair-railing.jpg",
      heading: "Glass Stair Railing",
      paragraph:
        "Beside stairway glass, glass stair railing is also available at Aspect with the same high quality. Glass railing serves as an alternative of wooden handrails. Variety of divergent glass stair railing are accessible here with their different kinds of basis such as trims, connectors, base shoes, rubber set, lights, glass railing clamps and adaptors depending on what you want to opt. We make sure to secure our glass railing panels with the top most quality of railing, bottom shoe or clips (either of glass or wood) depending on the design acquired by our client.",
    },
    {
      id: 6,
      img: "/img/projects/railing/balustrades.jpg",
      heading: "Balustrades",
      paragraph:
        "Seamless elegance and safety meet in our frameless glass balustrade fittings, complemented by the timeless sophistication of a stainless steel handrail. We are experienced and specialist in balustrades, A number of sophisticated safety proof, robust and rigid balustrades are also available of high quality steel, aluminium or glass. Different types of balustrades such as frameless and post system balustrades are available. Balusters can be used in your stairway to complete the look and add a design. Designers at Aspect are available who design customized balustrades according to clients needs and provide them the exact look the want that are fitted with high strength of frame and attractive curves that can be used in staircases, balconies, porch basically anywhere the client wants.",
    },
  ],
  upvc_sliding_windows: [
    {
      id: 1,
      img: "/img/projects/upvc/UPVC-Sliding-windows.webp",
      heading: "UPVC Windows",
      paragraph:
        "Our product lineup includes UPVC windows, which combine the durability of aluminium with the energy efficiency and low maintenance of UPVC materials. We take pride in our UPVC windows, designed to deliver superior performance and aesthetics. Our sliding windows are a popular choice, providing both functionality and elegance to any space. With various sliding window designs available, you can select the perfect style that suits your preferences.  Whether you choose our aluminium or UPVC sliding windows, you can trust Aspect Aluminium to deliver exceptional quality, durability, and style for your building projects.",
    },
    {
      id: 2,
      img: "/img/projects/upvc/upvc-sliding-windows.jpg",
      heading: "uPVC Sliding Windows",
      paragraph:
        "An uPVC Sliding window comprise of frames that can slide back and forth. The technical term for frames here is sash. With great ventilation and aesthetics, uPVC sliding windows offer unmatchable high-quality uPVC frames coupled with Glass of choice. We use premium materials for our UPVC sliding windows, ensuring their longevity and strength. Our attention to detail extends to the finest components, as we provide reliable sliding window parts crafted with precision.",
    },
  ],
  upvc_fixed_windows: [
    {
      id: 1,
      img: "/img/projects/upvc/upvc-fixed-window.jpg",
      heading: "UPVC Fixed Windows",
      paragraph:
        "The purpose of UPVC Fixed Windows is to provide architectural completion and aesthetical framing of the house. As the name suggests, they are fixed and don’t have any moving or operable parts. Usually these windows are installed at a height where ample amount of sunlight can enter the room",
    },
    {
      id: 2,
      img: "/img/projects/upvc/upvc-fixed-window2.webp",
      heading: "Fixed Frame Windows in Dubai",
      paragraph:
        "At Aspect Aluminium, we take pride in delivering exceptional products that combine functionality, elegance, and long-lasting performance. Experience the perfect blend of innovation and beauty with our comprehensive range of UPVC and aluminium fixed windows.  With our extensive selection of fixed frame windows, we provide versatile and stylish options to enhance any space. Our expert craftsmanship and attention to detail ensure that every window we offer, whether it's UPVC fixed windows or aluminium frame fixed glass windows, meets the highest standards of durability and aesthetics.  ",
    },
  ],
  upvc_tilt_and_turn_windows: [
    {
      id: 1,
      img: "/img/projects/upvc/upvc-tilt-and-turn2.jpg",
      heading: "Tilt & Turn Windows In Dubai",
      paragraph:
        "With our extensive range of products, including UPVC windows, aluminium windows, and upvc tilt and turn windows, we cater to the diverse needs and preferences of our customers. Our UPVC tilt and turn windows provide the ultimate versatility, allowing for easy ventilation and convenient cleaning with their unique dual functionality. Equipped with sturdy tilt and turn window handles, our products ensure effortless operation and enhanced security. Whether you prefer the sleek and modern appeal of aluminium or the energy-efficient properties of UPVC, our collection of tilt and turn windows is designed to elevate the aesthetics and performance of your space.",
    },
    {
      id: 2,
      img: "/img/projects/upvc/upvc-tilt-and-turn.jpg",
      heading: "UPVC Tilt & Turn Windows",
      paragraph:
        "UPVC Tilt and Turn Windows are a great choice when it comes to choosing a window as it has a perfect blend of intelligent operability and smart asthetics.",
    },
  ],

  upvc_doors: [
    {
      id: 1,
      img: "/img/projects/upvc/upvc-sliding-doors.jpg",
      heading: "uPVC Doors",
      paragraph:
        "Aspect Aluminium is a leading manufacturer of UPVC doors and windows in the UAE, offering a wide range of high-quality products. Whether you're looking to buy a UPVC door or seeking stylish and durable UPVC windows and doors, we have you covered. Our extensive selection includes coloured UPVC front doors, all designed to elevate the aesthetic appeal of your space. With competitive UPVC front door prices and a commitment to exceptional craftsmanship, we are your trusted source for top-notch UPVC doors and windows.",
    },
    {
      id: 2,
      img: "/img/projects/upvc/upvc-slide-fold.jpg",
      heading: "uPVC Slide & Fold Doors",
      paragraph:
        "uPVC Slide and Fold Doors are used mainly in large open areas to appear like a virtual wall. The door is a collection of multiple frames which has fixed or moving frames. These doors come in various patterns and styles, allowing you to quickly pick the right door for your home. They can be utilised as the primary entrance door and in the restrooms, kitchen, and bedrooms, among other places.",
    },

    {
      id: 3,
      img: "/img/projects/upvc/upvc-lift-slide.jpg",
      heading: "uPVC Lift & Sliding Door",
      paragraph:
        "Lift and Slide uPVC range of sliding doors are designed to accommodate large openings, creating expansive glass walls with unusually large panels. These glass doors give your home the convenience and space, which a hinged door cannot provide. They can be built straight, curved, or in a corner, while panels can slide against a jamb, into a wall pocket, or along an outside wall.",
    },
  ],
};

export const CareerData = [
  {
    id: 1,
    heading: "Accounts Manager",
    paragraph:
      "An Accountant or Accountant Professional is responsible for the management and reporting of financial data of an organization. Their duties include preparing financial statements, examining and analyzing a company’s accounts and ensuring compliance with financial reporting and other standard accounting procedures.",
    link: "/careers/accounts_manager",
  },
  {
    id: 2,
    heading: "Sales Manager",
    paragraph:
      "A Sales Manager, or Sales Department Manager, is responsible for overseeing daily operations in the sales department. Their duties include hiring and training sales staff, relaying information from upper management to department staff about sales quotas and generating leads to divide among Sales Representatives.",
    link: "/careers/sales_manager",
  },
  {
    id: 3,
    heading: "Assistant Accountant",
    paragraph:
      "An Accountant or Accountant Professional is responsible for the management and reporting of financial data of an organization. Their duties include preparing financial statements, examining and analyzing a company’s accounts and ensuring compliance with financial reporting and other standard accounting procedures.",
    link: "/careers/assistant_accountant",
  },
  {
    id: 4,
    heading: "Sales Representative",
    paragraph:
      "The Sales Representative is responsible for selling products and meeting customer needs while obtaining orders from existing or potential sales outlets. They ensure that the customer is satisfied and adequately taken care of while making a purchase. This way, they can establish new accounts for their employer",
    link: "/careers/sales_representative",
  },
];

export const CareerDescription = {
  accounts_manager: {
    job_description:
      "An Accountant or Accountant Professional is responsible for the management and reporting of financial data of an organization. Their duties include preparing financial statements, examining and analyzing a company’s accounts and ensuring compliance with financial reporting and other standard accounting procedures.",
    responsibilities: {
      paragraph:
        "Accountants prepare and examine financial records, ensuring records are accurate and that taxes are paid. Different types of Accountant duties and responsibilities may include the following:",
      lists: [
        "Reconciling The Company’s Bank Statements And Bookkeeping Ledgers",
        "Completing Analysis Of The Employee Expenditures",
        "Managing Income And Expenditure Accounts",
        "Generating The Company’s Financial Reports Using Income And Expenditure Data",
      ],
    },
    requirements: {
      paragraph:
        "We are seeking trustworthy candidates who work efficiently without sacrificing accuracy. Accountants can expect to work with large amounts of numerical data, handle multiple tasks with deadlines, and provide transparent and complete reports to management. You should have a firm grasp of accounting and financial practices and regulations and possess strong computer and verbal and written communication skills.",
      lists: [
        "Complying With All Company, Local, State, And Federal Accounting And Financial Regulations.",
        "Compiling, Analyzing, And Reporting Financial Data.",
        "Creating Periodic Reports, Such As Balance Sheets, Profit & Loss Statements, Etc.",
        "Presenting Data To Managers, Investors, And Other Entities.",
        "Maintaining Accurate Financial Records. Performing Audits And Resolving Discrepancies.",
      ],
    },
  },
  sales_manager: {
    job_description:
      "A Sales Manager, or Sales Department Manager, is responsible for overseeing daily operations in the sales department. Their duties include hiring and training sales staff, relaying information from upper management to department staff about sales quotas and generating leads to divide among Sales Representatives.",
    responsibilities: {
      paragraph:
        "For any business to thrive, it must have a competent Sales Manager who can plan, execute and oversee all sales and promotional activities effectively. Sales Managers typically undertake the following duties alone or in collaboration with other personnel in the sales department:",
      lists: [
        "Overseeing Local And Regional Sales, Promotions And Campaigns",
        "Planning And Directing The Hiring And Training Of New Sales Representatives",
        "Directing And Coordinating All Sales Activities Locally And Regionally",
        "Preparing Sales Budgets And Projections And Approving Expenditures",
        "Tracking And Analyzing Sales Statistics Based On Key Quantitative Metrics",
        "Handling And Resolving Customer Complaints Regarding A Product Or Service",
      ],
    },
    requirements: {
      paragraph:
        "In order to drive up sales while maintaining good relationships with customers, Sales Managers should have a passion for selling, be self-driven and have the following set of skills and qualifications:",
      lists: [
        "Ability To Set Sales Targets And Achieve Them Effectively",
        "Excellent Interpersonal, Customer Service And Communication Skills",
        "Experience Using CRM To Manage The Sales Process And Forecast Sales",
        "Strong Analytical Skills To Identify Trends And Sales Patterns",
        "Ability To Design And Implement A Successful Sales Strategy",
      ],
    },
  },
  assistant_accountant: {
    job_description:
      "We are looking for an accountant with great attention to detail, good with numbers and figures and an analytical acumen, good understanding of accounting and financial reporting principles and practices. He/She should have excellent knowledge of MS Office and familiarity with relevant computer software.",
    responsibilities: {
      paragraph:
        " Assisting the finance department and senior accounting staff members with various tasks, including preparing budgets, records, and statements",
      lists: [
        "Analyze balance sheets, income, cash flow statements, and other reports to assess accuracy.",
        "Maintain accurate month-end records by examining all financial statements.",
        "Handle monthly, quarterly and annual closings.",
        "Analyze reconciling bank statements, and monitor company finances to issue accurate and insightful reports within defined deadlines.",
      ],
    },
    requirements: {
      paragraph:
        "We are looking for an ambitious Assistant Accountant to provide support to the main business division of the finance department.",
      lists: [
        "Pertinent educational qualification in the field of Commerce, Finance, and Economics.",
        "Strong understanding of accounting aspects like balance sheets, cash flow management, business forecast, and profitability.",
        "Proven command of negotiation skills to build long-lasting partnerships with clients.",
        "Analyze reconciling bank statements, and monitor company finances to issue accurate and insightful reports within defined deadlines.",
        "Ability to prepare detailed and accurate reports within the given timeframe as per requirements.",
        "Qualified Microsoft Suite professional with a proven track record of implementing technology in financial tasks",
      ],
    },
  },
  sales_representative: {
    job_description:
      "The Sales Representative is responsible for selling products and meeting customer needs while obtaining orders from existing orpotential sales outlets. They ensure that the customer is satisfied and adequately taken care of while making a purchase. This way, they can establish new accounts for their employer.",
    responsibilities: {
      paragraph:
        "A Sales Representative uses their knowledge of company products or services to connect with consumers and generate sales for their employer",
      lists: [
        "Will be responsible for efficient coordination with clients for timely and smooth completion of job.",
        "Approach new potential clients by visiting sites, over telephone,email etc.",
        "Evaluate customers needs and build productive long-lasting relationships.",
        "Meet personal and team sales targets.",
        "Establish good relationship with current clients with follow up on their future projects.",
      ],
    },
    requirements: {
      paragraph:
        "Candidates must be able to achieve monthly sale targets and should have the ability to perform cost-benefits and needs analysis of existing/potential customers to meet their needs. Establish, develop and maintain positive business and customer problems and complaints to maximize satisfaction. Achieve agreed upon sales targets and outcomes within schedule Coordinate sales effort with team members and other departments keep abreast of best practices and promotional trends.",
      lists: [
        "Excellent communication, interpersonal and negotiation skills.",
        "Must be target oriented to achieve monthly Sale targets.",
        "Proven Sales experience and Self-motivated.",
        "Report and provide feedback to management weekly wise.",
        "Maintain and expand client database within your assigned territory Requirements and skills.",
        "Sending quotes to prospective clients, drafting contracts, Negotiations of commercial terms.",
        " Maintaining relationships with existing clients, Maintaining database of leads and clients in CRM.",
      ],
    },
  },
};

export const galleryImages = [
  {
    img: "/img/projects/doors/aluminum/aluminum-doors2.jpg",
    heading: "Aluminium Doors in Modern Colors",
    imagetype: "door",
  },
  {
    img: "/img/projects/glass-garden-rooms/rooms1.jpg",
    heading: "Garden Rooms",
    imagetype: "room",
  },
  {
    img: "/img/projects/glass-garden-rooms/rooms2.jpg",
    heading: "Beautiful Glass Rooms",
    imagetype: "room",
  },
  {
    img: "/img/projects/glass-garden-rooms/rooms3.jpg",
    heading: "Modern Garden Rooms",
    imagetype: "room",
  },
  {
    img: "/img/projects/glass-garden-rooms/rooms4.jpg",
    heading: "Glass Rooms",
    imagetype: "room",
  },
];
