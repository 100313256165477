import "./App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import RootLayout from "./layout/RootLayout";

import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import Product from "./pages/product/Product";

import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import ContactPage from "./pages/contactPage/ContactPage";
import SearchResults from "./pages/searchResults/SearchResults";
import About from "./pages/about/About";
import Projects from "./pages/projects/Projects";
import KitchenCabinets from "./pages/kitchenCabinets/KitchenCabinets";
import Careers from "./pages/careers/Careers";
import Career from "./pages/careers/Career";
import GlassRooms from "./pages/glassRooms/GlassRooms";
import GardenRooms from "./pages/gardenRooms/GardenRooms";
import Quotation from "./pages/quotation/Quotation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <Home /> },
      // { path: "/products/:material/:type", element: <Products /> },
      { path: "/:material/:type", element: <Products /> },
      { path: "/products/:material", element: <Products /> },
      { path: "/products/view-all/:all", element: <Products /> },
      { path: "/products/aluminium/:product", element: <Product /> },
      { path: "/products/glass/:product", element: <Product /> },
      { path: "/products/upvc/:product", element: <Product /> },
      { path: "/contact", element: <ContactPage /> },
      { path: "/search-results", element: <SearchResults /> },
      { path: "/about", element: <About /> },
      { path: "/projects", element: <Projects /> },
      { path: "/kitchen-cabinets", element: <KitchenCabinets /> },
      { path: "/glass-rooms", element: <GlassRooms /> },
      { path: "/garden-rooms", element: <GardenRooms /> },
      { path: "/quotation", element: <Quotation /> },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/careers/:job",
        element: <Career />,
      },
      { path: "*", element: <Home /> },
    ],
  },
]);

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return <RouterProvider router={router} />;
}

export default App;
