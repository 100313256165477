import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import "./social.scss";

const Social = () => {
  return (
    <nav className="social">
      <ul>
        <li>
          <a href="https://www.instagram.com/aspectaluminiumuae/">
            <span> Instagram</span>
            <FaInstagram className="social-icon insta" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/AspectAluminum">
            <span>Twitter</span>
            <FaTwitter className="social-icon twitter" />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/profile.php?id=100086641806388">
            <span> Facebook </span>
            <FaFacebook className="social-icon facebook" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Social;
