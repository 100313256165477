import React from "react";
import ProductsHandler from "../../components/productsHandler/ProductsHandler";
import { useNavigate, useParams } from "react-router-dom";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import { products } from "../../data/data";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Products = () => {
  const { type, material, all } = useParams();
  const filterList = () => {
    if (all) return products.filter((el) => el.type === all);
    if (type && material)
      return products.filter(
        (el) => el.material === material && el.type === type
      );
    if (!type) return products.filter((el) => el.material === material);
  };

  const lists = filterList();
  let title;
  if (all) title = all;
  else {
    title = `${material} ${type ? type : ""}`;
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (lists.length === 0) {
      return navigate("/");
    }
  }, [lists, navigate]);

  return (
    <>
      <Helmet>
        <title>{`${title} | Aspect Aluminium`}</title>
        <meta
          name="description"
          content="Best in Supply & Installation of Aluminium, uPVC, Glass, Glazing work in Dubai and all over UAE. Aspect Aluminium offers a great range of Services. Contact us to know about our Products"
        />
      </Helmet>
      <SimpleHeader title={title} />
      <ProductsHandler lists={lists} />
    </>
  );
};

export default Products;
