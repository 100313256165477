import React, { useContext, useEffect, useRef } from "react";
import { Button, Form } from "antd";
import FormContext from "../../context/MultiStepFormContext";

const Measure = () => {
  const { formDetails, setFormDetails, next, prev } = useContext(FormContext);

  const boardRef = useRef(null);
  const valueH = !formDetails.gridH ? 1 : formDetails.gridH;
  const valueV = !formDetails.gridV ? 1 : formDetails.gridV;
  useEffect(() => {
    if (valueH * valueV > 100) {
      boardRef.current.style.setProperty("--grid-row", 1);
      boardRef.current.style.setProperty("--grid-col", 1);
    } else {
      boardRef.current.style.setProperty("--grid-row", valueH);
      boardRef.current.style.setProperty("--grid-col", valueV);
    }

    if (valueH > 7 || valueV > 7)
      boardRef.current.style.setProperty("--grid-border", "2px");
  }, [valueH, valueV]);

  const createSquares = () => {
    let html = [];
    for (let i = 0; i < valueH * valueV; i++) {
      html.push(<div key={i} className="square"></div>);
      if (valueH * valueV > 100) {
        break;
      }
    }
    return html;
  };

  const handleNextClick = () => {
    next();
  };
  const widthRef = React.useRef();
  const heightRef = React.useRef();
  const onReset = () => {
    widthRef.current.value = "";
    heightRef.current.value = "";
    setFormDetails({
      ...formDetails,
      gridH: "",
      gridV: "",
      height: "",
      width: "",
    });
  };

  return (
    <div className="field measure--wrapper">
      <Form className="form--container" onFinish={handleNextClick}>
        <Form.Item name="gridh" initialValue={formDetails.gridH}>
          <div className="form--group">
            <label htmlFor="gridh">Grids Horizontal (optional):</label>
            <input
              type="number"
              placeholder="Select horizontal Grids "
              value={formDetails.gridH}
              min={1}
              max={10}
              name="gridh"
              
              onChange={(e) =>
                setFormDetails({ ...formDetails, gridH: e.target.value })
              }
            />
          </div>
        </Form.Item>
        <Form.Item name="gridV" initialValue={formDetails.gridV}>
          <div className="form--group">
            <label htmlFor="gridv">Grids Vertical (optional):</label>
            <input
              type="number"
              placeholder="Select Vertical Grids "
              value={formDetails.gridV}
              min={1}
              max={10}
              name="gridv"
              onChange={(e) =>
                setFormDetails({
                  ...formDetails,
                  gridV: Number(e.target.value),
                })
              }
            />
          </div>
        </Form.Item>
        <Form.Item initialValue={formDetails.width}>
          <div className="form--group">
            <label htmlFor="gridv">Width (mm):</label>
            <input
              type="number"
              placeholder="width in Millimetre"
              name="width"
              value={formDetails.width}
              ref={widthRef}
              min={10}
              onChange={(e) =>
                setFormDetails({ ...formDetails, width: e.target.value })
              }
            />
          </div>
        </Form.Item>
        <Form.Item>
          <div className="form--group">
            <label htmlFor="gridv">Height (mm):</label>
            <input
              type="number"
              placeholder="height in Millimetre"
              min={10}
              name="height"
              ref={heightRef}
              value={formDetails.height}
              onChange={(e) =>
                setFormDetails({ ...formDetails, height: e.target.value })
              }
            />
          </div>
        </Form.Item>
        <Form.Item>
          <div className="buttons">
            <Button type={"default"} onClick={prev}>
              Back
            </Button>
            <Button type={"primary"} htmlType="submit">
              Next
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
      <div className="gridImage">
        <div ref={boardRef} className="board board-anime">
          {createSquares()}
        </div>
      </div>
    </div>
  );
};

export default Measure;
