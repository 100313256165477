import "./glassRooms.scss";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import { BsDiamond } from "react-icons/bs";
import { Helmet } from "react-helmet";

const GlassRooms = () => {
  return (
    <div className="glassRooms">
      <SimpleHeader title={"glass rooms"} />
      <Helmet>
        <title>glass rooms | Aspect Aluminium</title>
        <meta
          name="description"
          content="Maximise your space with Aspect Glass Rooms, experience the freedom and luxury of extra space, make the best of your home. Transform your space with elegant and versatile glass rooms, bringing natural light and seamless indoor-outdoor living to your UAE property."
        />
        <meta
          name="keywords"
          content="glass rooms, aluminium glass rooms, glass room metal, glass rooms dubai, glass room door, glass room kits, glass room partition, glass enclosure, transparent spaces, glazed chambers, crystal chambers, translucent rooms, see-through spaces, panelled rooms, sunroom, atrium, conservatory, solarium, skylight, natural light, modern design, versatile space, light filled rooms, panoramic views, innovative living spaces"
        />
      </Helmet>
      <div className="glassRooms--container">
        <div className="row">
          <div className="content">
            <h2>Suitable Choice For all weather Conditions</h2>
            <p>
              All year round GlassRooms provide you with a comfortable
              environment for you to enjoy your beautiful outdoor area, while
              protecting you from the changing seasons, whether it be sun, wind
              or rain.
            </p>
            <p>
              Our Glass Rooms are tested for withstanding extreme winds and heat
              and keep you under perfect weather even in summer. Our Roof Glass
              panels reflects back the direct sunlight and heat upto 90% and
              keeps you cool in warm conditions.
            </p>
            <ul>
              <li>
                <BsDiamond />
                <span>Expert factory trained installers</span>
              </li>
              <li>
                <BsDiamond />
                <span>Excellent customer service</span>
              </li>
              <li>
                <BsDiamond />
                <span>Thermally and structurally proven</span>
              </li>
            </ul>
          </div>

          <div className="img">
            <img
              src="/img/projects/glass-garden-rooms/glass-rooms1.jpg"
              alt="Glass Rooms"
            />
          </div>
        </div>

        <div className="row">
          <div className="content">
            <h2>Stylish & Low Maintenance</h2>
            <p>
              We have a wide range of glass rooms, constructed to suit your
              lifestyle. Our glass rooms are made of high quality selected
              materials that have the added benefit of low maintenance.
            </p>
            <p>
              Maximise your space with Aspect Glass Rooms, experience the
              freedom and luxury of extra space, make the best of your home.
            </p>
            <ul>
              <li>
                <BsDiamond />
                <span>Traditional or Contemporary style</span>
              </li>
              <li>
                <BsDiamond />
                <span> Wide range of custom design</span>
              </li>
              <li>
                <BsDiamond />
                <span> Choice of uPVC or aluminium roofs</span>
              </li>
            </ul>
          </div>
          <div className="img">
            <img
              src="/img/projects/glass-garden-rooms/glass-rooms2.jpg"
              alt="Glass Rooms"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default GlassRooms;
