import "./simpleHeader.scss";

const SimpleHeader = ({ title }) => {
  return (
    <div className="simpleHeader">
      <div className="heading">
        <h1 className="main-heading">{title}</h1>
        <div className="underline"></div>
      </div>
    </div>
  );
};

export default SimpleHeader;
