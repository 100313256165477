import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CareerDetails from "../../components/careersData/career_details/CareerDetails";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import { CareerDescription } from "../../data/data";

const Career = () => {
  const navigate = useNavigate();
  const job = useParams().job;
  useEffect(() => {
    if (!CareerDescription[job]) {
      return navigate("/");
    }
  }, [job, navigate]);

  let title = job?.split("_").join(" ");

  return (
    <>
      <SimpleHeader title={title} />
      <CareerDetails CareerData={{ title, ...CareerDescription[job] }} />
    </>
  );
};

export default Career;
