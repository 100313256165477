import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import "./kitchenCabinets.scss";

const KitchenCabinets = () => {
  const data = [
    {
      id: 1,
      name: "Island",
      image:
        "https://media.istockphoto.com/id/1160708490/photo/kitchen-in-new-luxury-home.jpg?b=1&s=170667a&w=0&k=20&c=z_74u3TqDx6nJcTsYCkE5CXkEPCp9nve7ci2WEBfleA=",
    },
    {
      id: 2,
      name: "Forest",
      image:
        "https://images.unsplash.com/photo-1539922980492-38f6673af8dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nzh8fGtpdGNoZW4lMjBhbmQlMjBjYWJpbmV0c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 3,
      name: "Whale",
      image:
        "https://media.istockphoto.com/id/1453956647/photo/a-close-up-of-an-empty-high-white-modern-kitchen-with-windows-on-a-side.jpg?b=1&s=170667a&w=0&k=20&c=FJh0TtTKJGZ4U9XrkTSBdB7N1TIstEK9hmXxkBuqa9s=",
    },
    {
      id: 4,
      name: "Mountain",
      image:
        "https://images.unsplash.com/photo-1512916194211-3f2b7f5f7de3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTQzfHxraXRjaGVuJTIwYW5kJTIwY2FiaW5ldHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 5,
      name: "Boat",
      image:
        "https://plus.unsplash.com/premium_photo-1661962752158-f7b15d5ec42b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzF8fGtpdGNoZW4lMjBhbmQlMjBjYWJpbmV0c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 6,
      name: "Flowers",
      image:
        "https://images.unsplash.com/photo-1602028915047-37269d1a73f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8a2l0Y2hlbiUyMGNhYmluZXRzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 7,
      name: "Fire",
      image:
        "https://images.unsplash.com/photo-1586208958839-06c17cacdf08?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=965&q=80",
    },
    {
      id: 8,
      name: "Garden",
      image:
        "https://images.unsplash.com/photo-1538609589535-bb35f0c034db?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTM3fHxraXRjaGVuJTIwYW5kJTIwY2FiaW5ldHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    },
  ];

  return (
    <div className="kitchen">
      <SimpleHeader title={"Kitchen Cabinets"} />
      <div className="img">
        {data.map((img) => (
          <img src={img.image} key={img.id} alt={img.name} />
        ))}
      </div>
    </div>
  );
};

export default KitchenCabinets;
