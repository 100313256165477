import "./about.scss";
import Pdf from "../../Aspect-Aluminium-Industry-profile.pdf";
import { FaDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const About = () => {
  return (
    <>
      <Helmet>
        <title>about | Aspect Aluminium</title>
        <meta
          name="description"
          content=" Aspect – UAE, is a design, manufacture &
        installation company based in Dubai, United Arab Emirates and
        specializing in creative, design products & solutions."
        />
        <meta
          name="keywords"
          content="dubai, uae, sharjah, ajman, glass, aluminium, windows, doors, pergola, louver, upvc, glass rooms "
        />
      </Helmet>
      <div className="aboutPage">
        <div className="header">
          <div className="heading">
            <h2>About Us</h2>
            <div className="underline"></div>
          </div>
        </div>

        <div className="aboutPage--container">
          <div className="vision">
            <div className="tag">Who We Are</div>
            <p>
              Aspect Aluminium And Glass Is A Innovative Manufacturing Company
              Based In Dubai. We Are Specialized In Design, Manufacture &
              Installation Of Aluminum, Glass, Upvc, Glazing Work In Dubai And
              All Over UAE. We Offer A Wide Range Of Transparent Services in
              bifolding doors, sliding windows, pergolas, mashrabiya, bathroom
              partition, office partition, glass rooms, garden rooms,
              mashrabiya, curtain wall, Aluminium staircase, glass railing and
              much more . If You're Interested In Enriching Your Home Or
              Business With A Robust Yet Elegant Look, You're In The Right
              Place!
            </p>
            <p>
              In addition to our past projects, we always offer a complete
              product development, design and manufacture service – using our
              knowledge & experience within the field to create reliable,
              durable, functional and innovative solutions. We, provide you with
              any technical assistance & quotation related to your projects and
              requirements and to suit your budget.
            </p>
            <Link to="/projects">
              <button className="btn" style={{ margin: "2rem 1rem" }}>
                View Our Projects
              </button>
            </Link>
          </div>

          <div className="mission">
            <div className="mission--content">
              <div className="tag">Mission</div>
              <div className="mission--content--bottom">
                <div className="about--left">
                  <p>
                    To deliver exellent products, services and exceptional
                    customer experience through talent and technology
                  </p>
                  <div className="buttons">
                    <Link to="/quotation">
                      <button className="btn">Get A Quote</button>
                    </Link>
                    <a
                      className="brochure"
                      href={Pdf}
                      // download={"Aspect Aluminium & Glass"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="btn">
                        <FaDownload /> Company Profile
                      </button>
                    </a>
                  </div>
                </div>
                <div className="about--right">
                  <img src="/img/header/bg.webp" alt="doors and windows" />
                </div>
              </div>
            </div>
          </div>
          <div className="vision">
            <div className="tag">Vision</div>
            <p>
              Our Vision is to be the innovative market leader for Aluminium and
              Glass solutions in UAE.
            </p>
            <p>Our Focus is on:</p>
            <div className="circles">
              <div className="circle">
                <h3>Quality</h3>
                <p>
                  We take pride in our ability to execute flawlessly, exceed
                  expectation and create superior value
                </p>
              </div>
              <div className="circle">
                <h3>Integrity</h3>
                <p>
                  We are honest in all we do and demonstrate strong mortal
                  principles and ethical convictions.
                </p>
              </div>
              <div className="circle">
                <h3>Innovation</h3>
                <p>
                  We are entrepreneurial pursuing new and bold ideas to
                  transform what we do
                </p>
              </div>
              <div className="circle">
                <h3>Customer Centricity</h3>
                <p>
                  We accurately identify our customers needs and desires so that
                  we strive to meet and exceed their expectations by delivering
                  value
                </p>
              </div>
              <div className="circle">
                <h3>Collaboration</h3>
                <p>We are commited to work in collaborative environment </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
